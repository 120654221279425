// PICKS OUT A PROPERTY FROM THE FONT DATABASE
@function font-property($font-name, $property) {
    @for $i from 1 to $config-length {
        $font-name-index:#{$i};
        $font: map-get(map-get($dfr-config, 'font' + $font-name-index), "name");

        @if $font == $font-name {
            @return #{map-get(map-get($dfr-config, 'font' + $font-name-index), "#{$property}")};
        }
    }
}

// CHECKS IF THE INPUT SIZE FIT INTO THE TEMPLATE
@mixin warning-handler($space, $shutup: 0) {
    @if $shutup != 2 {
        $spaces: map-get($dfr-config, spaces);
        $spaces-length: length($spaces);
        $lower: 0;
        $upper: 0;
    
        $k: 0;
        @while nth($spaces, $k + 1) < $space and $k < $spaces-length - 1 {
            $k: $k + 1;
        }
    
        @if $k <= $spaces-length and $k > 0 {
            $lower: nth($spaces, $k);
        }
        @if nth($spaces, $spaces-length) < $space {
            $lower: nth($spaces, $spaces-length);
        }
        @if nth($spaces, $k + 1) == $space {
            $lower: 0;
        }
    
        $j: 9;
        @while nth($spaces, $j) > $space and $j > 1 {
            $j: $j - 1;
        }
        
        @if $j <= $spaces-length and $j < $spaces-length {
            $upper: nth($spaces, $j + 1);
        }
        @if nth($spaces, 1) > $space {
            $upper: nth($spaces, 1);
        }
        @if nth($spaces, $j) == $space {
            $upper: 0;
        }
        @if $space != 0rem {
            @if $upper != 0 and $lower != 0 {
                @warn '||| === WARNING SIZE DOES NOT MATCH THE TEMPLATE! = > CLOSEST SIZES: #{$lower} AND #{$upper} === |||';
            }
            @else if $upper == 0 and $lower > 0 {
            @warn '||| === WARNING SIZE DOES NOT MATCH THE TEMPLATE! = > CLOSEST SIZE: #{$lower} lower === |||';
            }
            @else if $lower == 0 and $upper > 0 {
                @warn '||| === WARNING SIZE DOES NOT MATCH THE TEMPLATE! = > CLOSEST SIZE: #{$upper} === |||';
            }
        }
    }
}

// UNDER DEVELOPMENT
// @mixin custom-font($font-name, $font-family, $font-weight, $font-size, $line-height, $padding-top: 0rem, $margin-bottom: 0rem) {
//     $temp: (
//         'font#{$config-length}': (
//             'name': '#{$font-name}',
//             'font-family': #{$font-family},
//             'font-weight': #{$font-weight},
//             'font-size': #{$font-size},
//             'line-height': #{$line-height},
//             'margin-bottom': #{$margin-bottom},
//             'padding-top': #{$padding-top}
//             )
//         );
//     $dfr-config: map-merge($dfr-config, $temp);
// }

@mixin font-exists($font) {
    $i: 1;
    $font-name-index: '1';

    @while $i < $config-length and map-get(map-get($dfr-config, 'font' + $font-name-index), "name") != $font{
        $font-name-index: #{$i};
        $i: $i + 1;
    }

    @if $i == $config-length {
        @error 'ERROR UNKNOWN FONT NAME! EXAMPLE SYNTAX FOR FONTS: OBb14 / B-R14';
    }
}

@mixin get-font($input-font) {
    // @include font-exists($input-font);
    
    font-family: font-property($input-font, font-family);
    font-weight:font-property($input-font, font-weight);
    font-size: font-property($input-font, font-size);
    line-height: font-property($input-font, line-height);
    padding-top: font-property($input-font, padding-top);
    margin-bottom: font-property($input-font, margin-bottom);
}

@mixin get-regular-font($input-font) {
    @include font-exists($input-font);
    font-family: font-property($input-font, font-family);
    font-weight:font-property($input-font, font-weight);
    font-size: font-property($input-font, font-size);
    line-height: font-property($input-font, line-height);
}

// MARGIN TOP CALCULATOR FOR FONTS
// IF YOU WANT TO GIVE YOUR FONTS A MARGIN TOP USE THIS
// IT CALCULATES THE MARGIN FROM THE BOTTOM OF THE FIRST ROW
@mixin margin-top($font-name-input, $margin, $shutup: 0) {
    @include warning-handler($margin, $shutup);

    // @include font-exists($font-name-input);
    $line-height: font-property($font-name-input, line-height);
    $font-size: font-property($font-name-input, font-size);
    $offset: font-property($font-name-input, padding-top);

    margin-top: calc(#{$margin} - #{$line-height} - #{$offset});
}

// THIS IS FOR THE CONTAINER BLOCKS IF YOU PUT THE FONT TYPES ON THE CONTAINER ITSELF
// THE FONT WILL MAKE A PADDING TOP ON THE CONTAINER TO MAKE SURE THE FONT IS ON THE SASSLINE TEMPLATE
// SO IF YOU WANT TO ADD PADDING TOP ON A CONTAINER USE THIS!!
@mixin padding-top($font-name-input, $container-padding, $shutup: 0) {
    @include font-exists($input-font);

    @include warning-handler($container-padding, $shutup);

    $font-padding: font-property($font-name-input, padding-top);

    padding-top: calc(#{$font-padding} + #{$container-padding});
}

// THIS IS FOR THE FONTS MARGIN BOTTOM CALCULATION BECAUSE OF SASSLINE THE FONTS HAVE A NEGATIVE MARGIN
// SO YOU HAVE TO BALANCE IT OUT
// USE THIS MIXIN FOR THAT
@mixin margin-bottom($font-name-input, $margin, $shutup: 0) {
    @include font-exists($font-name-input);

    @include warning-handler($margin, $shutup);

    $font-margin: font-property($font-name-input, margin-bottom);

    margin-bottom: calc(#{$margin} + #{$font-margin});
}

@mixin sassline($font, $font-margin-top: 0, $font-margin-bottom: 0,$font-padding-top: 0,$font-padding-bottom: 0,$killed: 0, $shutup: 0) {
    @if $killed != 1 and $font-margin-top != 1 and $font-margin-bottom != 1 and $font-padding-top != 1 and $font-padding-bottom != 1 {
        @include get-font($font);
    } @else {
        @include get-regular-font($font);
    }
    @if $font-margin-top != $kill and $font-margin-top != 0 {
        @include margin-top($font, $font-margin-top);
    }
    @if $font-margin-bottom != $kill and $font-margin-bottom != 0 {
        @include margin-bottom($font, $font-margin-bottom);
    }
    @if $font-padding-top != $kill and $font-padding-top != 0 {
        @include padding-top($font, $font-padding-top);
    }
    @if $font-padding-bottom != $kill and $font-padding-bottom != 0 {
        @include warning-handler($font-padding-bottom, $shutup);
        padding-bottom: $font-padding-bottom;
    }
}

@mixin shadow() {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: box-shadow var(--fast);
}

@mixin shadow-hover() {
    &:hover {
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    }
}

@mixin border-radius() {
    border-radius: 6px;

    @include media(phone) {
        border-radius: 0;
    }
}