.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: var(--gutter);

    color: var(--light-grey-3);
    list-style: none;
}

.pagination-selected {
    background-color: var(--light-green)!important;
    color: black!important;
}

.pagination li button {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;

    color: var(--light-grey-3);
    font-weight: 900;
    font-size: 1.25rem;
}
.pagination li button:disabled {
    color: var(--light-grey-1);
}

.pagination li button:hover:not([disabled]) {
    cursor: pointer;
    color: var(--green-3);
    background-color: var(--light-green);
    border-radius: 50%;
}
