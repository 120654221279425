.section-highlighted-article {
    @extend .top-highlighted-article;
    margin-top: $s156;
    position: relative;

    .section-title {
        margin-top: $s18;
    }

    .highlighted-article__header {
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        top: unset;
    }

    .highlighted-article__body-container {
        grid-column: 1 / -1;
        position: relative;
        @include full-grid;
        grid-row-gap: 0;
        position: relative;
        z-index: 5;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 22.25rem;
            z-index: -1;
            background-color: var(--light-grey-1);
        }
    }
}
