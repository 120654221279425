.image-widget {
    @include full-grid;
    grid-column: 1 / -1;
    grid-row-gap: 1.125rem;
    grid-auto-rows: 1fr;
    margin-top: 3rem;

    & + .image-widget {
        margin-top: 0;

        .paper {
            top: 0;
        }
    }

    & > div:not(.react-carousel) {
        height: 26.25rem;
    }

    &.image-single {
        margin-top: 0;
        height: 0;
        z-index: 100;
        position: relative;
        top: $s54;

        & + .text-widget {
            min-height: 30.75rem;
        }

        .image-left-small > div {
            border-radius: 0.375rem !important;
        }

        @include media(tab-land) {
            height: auto;
            top: 0;
            padding-top: $s54;
            z-index: unset;

            & + .text-widget {
                min-height: unset;
            }

            .image-left-small {
                grid-column: 5 / 11;
                margin-top: 0;
                width: 22.5rem;
                justify-self: center;
                margin: 0px;
            }
        }

        .paper {
            display: none;
            height: calc(100% + #{$s54}) !important;
            top: -$s54;

            @include media(tab-land) {
                display: block;
            }
        }
    }

    & > * {
        &:not(.paper) {
            position: relative;
            z-index: 100;
            grid-row: 1 / 2;
            margin-top: $s18;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                @include shadow;
                z-index: -1;
            }
        }
    }

    .paper {
        position: absolute;
        grid-column: var(--col-5-11-grid);
        background-color: var(--whitish);
        padding: var(--col-5-11-padding);
        position: relative;
        width: 100%;
        grid-row: 1 / 2;
        height: calc(100% + 3rem) !important;
        top: -3rem;
        z-index: -1;

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: -1;
            @include shadow;
        }
    }

    .border-rad-top-left {
        &::after,
        & > div {
            border-top-left-radius: 0.375rem;
            @include media(tab-port) {
                border-top-left-radius: 0rem;
            }
        }
    }

    .border-rad-top-right {
        &::after,
        & > div {
            border-top-right-radius: 0.375rem;
            @include media(tab-port) {
                border-top-right-radius: 0rem;
            }
        }
    }

    .border-rad-bottom-left {
        &::after,
        & > div {
            border-bottom-left-radius: 0.375rem;
            @include media(tab-port) {
                border-bottom-left-radius: 0rem;
            }
        }
    }

    .border-rad-bottom-right {
        &::after,
        & > div {
            border-bottom-right-radius: 0.375rem;
            @include media(tab-port) {
                border-bottom-right-radius: 0rem;
            }
        }
    }

    .image-left-medium {
        grid-column: 2 / 8;
    }

    .image-right-medium {
        grid-column: 8 / 14;
    }

    .image-left-small {
        grid-column: 2 / 6;
        margin-right: 1.25rem;
        position: relative;

        @include media(phone) {
            grid-column: 1 / 6;
        }

        .caption {
            position: absolute;
            top: calc(100% + 0.8125rem);
            font-size: 0.875rem;
            line-height: 1.3125rem;
            color: var(--dark-grey-7);
            grid-column: 2 / 4;
            margin-right: 5rem;
        }
    }

    .image-center-small {
        grid-column: 6 / 10;
        width: calc(100% + 2.5rem);
        margin-left: -1.25rem;
    }

    .image-center-large {
        grid-column: 6 / 12;
        img {
            border-radius: 0.375rem;
        }
    }

    .image-right-small {
        grid-column: 10 / 14;
        margin-left: 1.25rem;

        @include media(phone) {
            grid-column: 10 / -1;
        }
    }

    .image-right-large {
        grid-column: 6 / 14;
        width: calc(100% + 1.25rem);
        margin-left: -1.25rem;

        @include media(phone) {
            grid-column: 6 / -1;
        }
    }

    .image-left-large {
        grid-column: 2 / 10;
        width: calc(100% + 1.25rem);
    }
}
