$s12: 0.75rem;
$s18: 1.125rem;
$s24: 1.5rem;
$s36: 2.25rem;
$s54: 3.375rem;
$s78: 4.875rem;
$s114: 7.125rem;
$s156: 9.75rem;
$s204: 12.75rem;
$kill: 1;
$shutup: 2;



// ALL FONTS IS POSITIONED ON THEIR LINE-HEIGHTS BOTTOM
// THERE IS ONE PLUS MAP IN THE DFR-CONFIG SO THE RANGE IS JUST LENGTH
// FONT DATABASE
$dfr-config: (
    'spaces': ( 0.75rem, 1.125rem, 1.5rem,
    2.25rem, 3.375rem, 4.875rem,
    7.125rem, 9.75rem, 12.75rem),
    'font1': (
        'name': 'R14',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'normal',
        'font-size': 0.875rem,
        'line-height': 1.3125rem,
        'padding-top': 0.34375rem,
        'margin-bottom': -0.34375rem,
    ),

    'font2': (
        //done
        'name': 'Rb14',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'bold',
        'font-size': 0.875rem,
        'line-height': 1.3125rem,
        'margin-bottom': -0.355rem,
        'padding-top': 0.355rem
    ),

    'font3': (
        'name': 'OS14',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'normal',
        'font-size': 0.875rem,
        'line-height': 1.3125rem,
        'margin-bottom': -0.31875rem,
        'padding-top': 0.31875rem
    ),

    'font4': (
        'name': 'OSb14',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'bold',
        'font-size': 0.875rem,
        'line-height': 1.3125rem,
        'margin-bottom': -0.31875rem,
        'padding-top': 0.31875rem
    ),

    'font5': (
        'name': 'R16',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'normal',
        'font-size': 1rem,
        'line-height': 1.5rem,
        'padding-top': 0.39375rem,
        'margin-bottom': -0.39375em
    ),

    'font6': (
        //done
        'name': 'Rb16',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'bold',
        'font-size': 1rem,
        'line-height': 1.5rem,
        'margin-bottom': -0.39375em,
        'padding-top': 0.39375em
    ),

    'font7': (
        'name': 'OS16',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'normal',
        'font-size': 1rem,
        'line-height': 1.5rem,
        'margin-bottom': -0.35625rem,
        'padding-top': 0.35625rem
    ),

    'font8': (
        'name': 'OSb16',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'bold',
        'font-size': 1rem,
        'line-height': 1.5rem,
        'margin-bottom': -0.35625rem,
        'padding-top': 0.35625rem,
    ),

    'font9': (
        'name': 'R20',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'normal',
        'font-size': 1.25rem,
        'line-height': 1.875rem,
        'padding-top': 0.49375rem,
        'margin-bottom': -0.49375rem
    ),

    'font10': (
        //done
        'name': 'Rb20',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'bold',
        'font-size': 1.25rem,
        'line-height': 1.875rem,
        'margin-bottom': -0.49375rem,
        'padding-top': 0.49375rem
     ),

    'font11': (
        'name': 'OS20',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'normal',
        'font-size': 1.25rem,
        'line-height': 1.875rem,
        'margin-bottom': -0.44375rem,
        'padding-top': 0.44375rem
    ),

    'font12': (
        'name': 'OSb20',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'bold',
        'font-size': 1.25rem,
        'line-height': 1.875rem,
        'margin-bottom': -0.44375rem,
        'padding-top': 0.44375rem
    ),

    'font13': (
        'name': 'R25',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'normal',
        'font-size': 1.5625rem,
        'line-height': 2.25rem,
        'padding-top': 0.56875rem,
        'margin-bottom': -0.56875rem
    ),

    'font14': (
        //done
        'name': 'Rb25',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'bold',
        'font-size': 1.5625rem,
        'line-height': 1.875rem,
        'margin-bottom': -0.38125rem,
        'padding-top': 0.38125rem
    ),

    'font15': (
        'name': 'OS25',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'normal',
        'font-size': 1.5625rem,
        'line-height': 2.25rem,
        'margin-bottom': -0.51875rem,
        'padding-top': 0.51875rem
    ),

    'font16': (
        'name': 'OSb25',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'bold',
        'font-size': 1.5625rem,
        'line-height': 1.875rem,
        'margin-bottom': -0.33125rem,
        'padding-top': 0.33125rem
    ),

    'font17': (
        'name': 'Rb31',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'bold',
        'font-size': 1.9375rem,
        'line-height': 2.25rem,
        'margin-bottom': -0.44375rem,
        'padding-top': 0.44375rem
    ),

    'font18': (
        'name': 'OSb31',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'bold',
        'font-size': 1.9375rem,
        'line-height': 2.25rem,
        'padding-top': 0.375rem,
        'margin-bottom': -0.375rem
    ),

    'font19': (
        //done
        'name': 'Rb39',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'bold',
        'font-size': 2.4375rem,
        'line-height': 3rem,
        'padding-top': 0.64375rem,
        'margin-bottom': -0.64375rem
    ),

    'font20': (
        'name': 'OSb39',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'bold',
        'font-size': 2.4375rem,
        'line-height': 3rem,
        'padding-top': 0.55625rem,
        'margin-bottom': -0.55625rem
    ),
    
    'font21': (
        'name': 'Rb48',
        'font-family': ('Roboto', sans-serif),
        'font-weight': 'bold',
        'font-size': 3rem,
        'line-height': 3.375rem,
        'padding-top': 0.63125rem,
        'margin-bottom': -0.63125rem
    ),

    'font22': (
        'name': 'OSb48',
        'font-family': ('Open Sans', sans-serif),
        'font-weight': 'bold',
        'font-size': 3rem,
        'line-height': 3.375rem,
        'padding-top': 0.525rem,
        'margin-bottom': -0.525rem
    )
);

$config-length: length($dfr-config);


@mixin separator() {
    $separator: list-separator($dfr-config);
    @warn $separator;
}