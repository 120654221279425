.tags-filter {
    .selected-tags {
        margin-bottom: var(--gutter);

        .tag {
            outline:none;
            border:none;
            background-color: var(--green-3);
            color: white;
            animation: NewTag var(--fast);
            padding: 0 0 0 1.25rem;

            &:hover > .fa-close {
                    opacity: 1;
            }

            .fa-close {
                opacity: 0.8;
                padding: 0.5rem 1.25rem 0.5rem 0.9375rem;
                line-height: $s24;
                cursor: pointer;
                
                &::before {
                    content: "\f00d";
                }
            }
        }
    }
}

@keyframes NewTag {
    0% {
        clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}
