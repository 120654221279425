.u-m-t-12 {
    margin-top: $s12 !important;
}
.u-m-t-18 {
    margin-top: $s18 !important;
}
.u-m-t-24 {
    margin-top: $s24 !important;
}
.u-m-t-36 {
    margin-top: $s36 !important;
}
.u-m-t-54 {
    margin-top: $s54 !important;
}
.u-m-t-78 {
    margin-top: $s78 !important;
}
.u-m-t-114 {
    margin-top: $s114 !important;
}
.u-m-t-156 {
    margin-top: $s156 !important;
}
.u-m-t-204 {
    margin-top: $s204 !important;
}

.u-m-b-12 {
    margin-bottom: $s12 !important;
}
.u-m-b-18 {
    margin-bottom: $s18 !important;
}
.u-m-b-24 {
    margin-bottom: $s24 !important;
}
.u-m-b-36 {
    margin-bottom: $s36 !important;
}
.u-m-b-54 {
    margin-bottom: $s54 !important;
}
.u-m-b-78 {
    margin-bottom: $s78 !important;
}
.u-m-b-114 {
    margin-bottom: $s114 !important;
}
.u-m-b-156 {
    margin-bottom: $s156 !important;
}
.u-m-b-204 {
    margin-bottom: $s204 !important;
}