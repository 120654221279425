.top-highlighted-article {
  grid-column: 1 / -1;
  z-index: 1;

  @include full-grid;
  grid-row-gap: 0;
  transition: box-shadow var(--slow);
  z-index: auto !important;

  .highlighted-article__cover {
    grid-column: 1 / -1;
    grid-row: 1 / 3;
    min-height: 21.875rem;
    height: 30vw;
    max-height: 37.5rem;
    width: 100%;
    overflow: hidden;
    user-select: none;

    img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
    }
  }


  .highlighted-article__header {
    grid-row: 2 / 3;
    grid-column: col-start 4 / col-end 11;
    align-self: end;
    background-color: white;
    position: relative;
    top: 2px;
    border-radius: 0.375rem 0.375rem 0 0;
    z-index: auto;

    &::before {
      @include shadow;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      transition: box-shadow var(--slow);
    }

    &.shadow::before {
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    }

    @include media(phone) {
      border-radius: 0;
    }

    display: grid;
    grid-template-columns: repeat(8, [col-start] 1fr [col-end]);
    grid-row-gap: 0.875rem;
    grid-column-gap: var(--gutter);
    animation: fadeIn var(--slow);

    .labels {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 1.25rem 1.875rem 0;
      @extend .label;
      grid-row: 1 / 2;
      grid-column: 1 / -1;
    }

    .author {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
      color: var(--blue);
      z-index: 11;
      font-style: italic;
      justify-self: end;
      text-transform: capitalize;

      &:hover {
        text-decoration: underline;
        color: var(--green-7);
      }
    }

    .title {
      padding-left: 1.875rem;
      grid-column: col-start 1 / -2;
      grid-row: 2 / 3;
      align-self: end;
      z-index: 11;
      cursor: pointer;
      color: var(--dark-grey-4);
      @extend .h1;

      overflow: hidden;
      @supports (-webkit-line-clamp: 2) and (display: -webkit-box) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
      }

      &:hover {
        color: var(--green-3);
        text-decoration: underline;
      }

      &.hover {
        color: var(--green-3);
        text-decoration: underline;
      }

      @supports not (-webkit-line-clamp: 3) {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .highlighted-article__body-wrapper {
    grid-column: 1 / -1;
    position: relative;
    @include full-grid;
    grid-row-gap: 0;
    position: relative;
    z-index: 5;

    &.overflow-hidden {
      overflow: hidden;
    }

    .highlighted-article__body {
      position: relative;
      border-radius: 0 0 0.375rem 0.375rem;
      grid-row: 3 / 4;
      grid-column: col-start 4 / col-end 11;
      align-self: start;
      background-color: var(--whitish);
      margin-bottom: 3rem;
      transform: translateZ(0);
      transition: box-shadow var(--fast);

      animation: fadeIn var(--slow);
      z-index: auto;

      &::before {
        @include shadow;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      &.shadow::before {
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
      }

      display: grid;
      grid-template-columns: repeat(8, [col-start] 1fr [col-end]);
      grid-column-gap: var(--gutter);

      @include media(phone) {
        border-radius: 0;
      }

      .text {
        margin: 0.75rem 1.875rem $s18;
        grid-column: col-start 1 / -2;
        grid-row: 1;
      }

      .tags {
        margin: 0 1.875rem 2.625rem;
        grid-column: 1 / -1;
        margin-bottom: 0.875rem;
        z-index: 11;
      }
    }
  }


  .arrow {
    grid-column: -2 / -1;
    align-self: center;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 3.125rem;
    height: 3.125rem;
    border: 0px;
    border-radius: 2.5rem;
    background-position: center;
    background-color: inherit;
    transition: background var(--fast), color var(--fast);

    font-size: 1.875rem;
    color: var(--light-grey-2);
    cursor: pointer;

    @include ripple(3.125rem);

    &.hover {
      color: var(--green-4);
      background: var(--light-green) radial-gradient(circle, transparent 1%, var(--light-green) 1%) center/15000%;
    }
  }
}

@include media(tab-land) {
  .top-highlighted-article {
    .highlighted-article__header,
    .highlighted-article__body-wrapper .highlighted-article__body {
      grid-column: col-start 2 / col-end 11;
    }
  }
}

@include media(tab-port) {
  .top-highlighted-article {
    .highlighted-article__header,
    .highlighted-article__body-wrapper .highlighted-article__body {
      grid-column: main-start / main-end;
    }

    .highlighted-article__header {
      padding: 0.625rem 1.25rem 0 1.25rem;
      grid-template-columns: 1fr;
      grid-row-gap: 0.625rem;

      .label {
        padding: 0.625rem 0.625rem 0 0;
      }

      .title {
        grid-column: 1 / 2;
        grid-row: auto;
        padding: 0;
      }
    }

    .highlighted-article__body-wrapper .highlighted-article__body {
      .arrow {
        display: none;
      }

      margin-bottom: 3.125rem;
      padding: 0.625rem 1.25rem;

      .text {
        grid-column: 1 / -1;
        margin-left: 0;
        margin-right: 0;
      }

      .tags {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@include media(phone) {
  .top-highlighted-article {
    .highlighted-article__header,
    .highlighted-article__body-wrapper .highlighted-article__body {
      grid-column: 1 / -1;
    }
  }
}
