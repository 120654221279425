.system-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: var(--whitish-2);
    height: 18.75rem;
    color: var(--dark-grey-3);
    grid-column: main-start/main-end;
    overflow: hidden;
    z-index: 1;
    border: 1px solid var(--light-grey-4);
    @extend .u-m-b-78;
    @include border-radius();
    @include shadow;

    @include media(phone) {
        grid-column: full-start / full-end;
        height: 25rem;
        border-radius: 0;
    }

    .animation-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;

        @include media(tab-land) {
            max-width: calc(100% - 9rem);
            left: 4.5rem;
        }

        &__title {
            @extend .h1;
            margin-bottom: 0.5rem;
        }

        &--animation-slide-in-right {
            animation: slideInRight var(--slow);
        }

        &--animation-slide-in-left {
            animation: slideInLeft var(--slow);
        }

        &--animation-slide-out-right {
            animation: slideOutRight var(--slow) forwards;
        }

        &--animation-slide-out-left {
            animation: slideOutLeft var(--slow) forwards;
        }

        .content {
            max-width: 48.75rem;
            @include center;
            flex-direction: column;
            height: 100%;
            overflow-y: auto;
            padding: 20px 0;
            justify-content: unset;

            &__title {
                @extend .h1;
                margin-bottom: 0.5rem;
            }

            & > *:not(.content__title):not(:last-child) {
                margin-bottom: $s18;
            }

            &__title {
                text-align: center;
            }

            p {
                @include media(phone) {
                    text-align: center;
                }

                a:hover {
                    text-decoration: underline;
                }
            }

            &__btn {
                @include center;
                @include ripple(23.75rem);
                width: 23.75rem;
                height: 3rem;
                background-color: var(--green-2);
                border-radius: $s24;
                color: white;
                text-transform: uppercase;
                cursor: pointer;
                @extend .B-Rb20;
                transition: all var(--fast);
                user-select: none;

                &:hover {
                    background-color: var(--green-3);
                }

                @include media(tab-port) {
                    width: 18.75rem;
                }

                @include media(phone) {
                    font-size: 1rem;
                    width: 12.5rem;
                }
            }

            &__login-text {
                color: var(--edl-color-gray60);

                a:hover {
                    color: var(--green-7);
                }
                a {
                    color: var(--edl-color-cerulean60)
                }
            }
        }
    }
    .arrow {
        @include center;
        font-size: 2.5rem;
        color: var(--dark-grey-7);
        cursor: pointer;
        z-index: 10;
        transition: color var(--fast);
        user-select: none;

        @include media(tab-land) {
            height: 100%;
        }

        &:first-of-type {
            padding: 2.5625rem;

            @include media(tab-land) {
                padding-left: 0.375rem;
            }
        }

        &:last-of-type {
            padding: 2.5625rem;

            @include media(tab-land) {
                padding-left: 0.375rem;
            }
        }

        &:hover {
            color: var(--dark-grey-2);
        }
    }
}
