.top-articles {
    grid-column: 1 / -1;
    position: relative;

    @include full-grid;

    .card-holder {
        z-index: 1;

        @include media(phone) {
            grid-column: 1 / -1;
        }

        .article-card__image {
            height: 21vw;
            min-height: 18.75rem;
            height: 26.25rem;
        }
    }

    &::before {
        position: absolute;
        display: block;
        background-color: var(--light-grey-5);
        content: '';
        height: 26.25rem;
        width: 100%;
        z-index: 0;
        top: -7.5rem;
    }
}
