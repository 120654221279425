.section-selector-2 {
    display: flex;
    flex-direction: column;
    position: relative;
    @include margin-top(OS16, 3rem, $shutup);

    &::after {
        position: absolute;
        left: 0;
        content: '';
        display: block;
        width: 0.3125rem;
        height: 100%;
        background: linear-gradient(var(--whitish-2) 1.25rem, var(--light-green), var(--light-green), var(--whitish-2));
    }

    .title {
        @include get-regular-font(R25);
        color: var(--grey-3);
        padding: 0.9375em;
        padding-left: $s18;
    }

    .list-wrapper {
        display: flex;
        flex-direction: column;
    }

    @include media(tab-land) {
        .list-wrapper {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .database-selector {
        background: none;
        border: none;
        outline: inherit;
        text-align: left;
        @include get-regular-font(Rb20);
        padding: 0.9375rem;
        padding-left: 2.1875rem;
        color: var(--blackish);
        cursor: pointer;
        background-size: 200% 100%;
        overflow: hidden;
        transition: background 0.2s linear, background-position 0.2s linear;
        position: relative;
        z-index: 1;
        user-select: none;

        &.active {
            color: var(--green-4);
            background-position: -100% 0%;
            border-radius: 0 0.375rem 0.375rem 0;
        }
    }

    .item {
        @include get-regular-font(Rb20);
        padding: 0.9375rem;
        padding-left: 1.25rem;
        color: var(--blackish);
    }

    .list {
        list-style: none;
        counter-reset: counter;
        list-style-type: upper-roman;

        li {
            counter-increment: counter;
            cursor: pointer;
            overflow: hidden;
            position: relative;
            z-index: 1;
            user-select: none;

            &::after {
                display: block;
                content: '';
                background-color: var(--light-green);
                position: absolute;
                left: -100%;
                top: 0;
                transition: left var(--fast);
                height: 100%;
                width: 100%;
                border-radius: 0 1.6875rem 1.6875rem 0;
                z-index: -1;
            }

            &:hover {
                color: var(--green-4);
                text-decoration: underline;
            }

            &::before {
                content: counter(counter, upper-roman) '. ';
                color: var(--grey);
            }

            &.active {
                color: var(--green-4);
                text-decoration: none !important;

                &::after {
                    left: 0;
                }

                @include media(tab-port) {
                    border-radius: 0;
                }
            }
        }
    }
}
