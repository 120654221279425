.u-m-t-12 {
  margin-top: 0.75rem !important; }

.u-m-t-18 {
  margin-top: 1.125rem !important; }

.u-m-t-24 {
  margin-top: 1.5rem !important; }

.u-m-t-36 {
  margin-top: 2.25rem !important; }

.u-m-t-54 {
  margin-top: 3.375rem !important; }

.u-m-t-78 {
  margin-top: 4.875rem !important; }

.u-m-t-114 {
  margin-top: 7.125rem !important; }

.u-m-t-156 {
  margin-top: 9.75rem !important; }

.u-m-t-204 {
  margin-top: 12.75rem !important; }

.u-m-b-12 {
  margin-bottom: 0.75rem !important; }

.u-m-b-18 {
  margin-bottom: 1.125rem !important; }

.u-m-b-24 {
  margin-bottom: 1.5rem !important; }

.u-m-b-36 {
  margin-bottom: 2.25rem !important; }

.u-m-b-54 {
  margin-bottom: 3.375rem !important; }

.u-m-b-78, .system-message {
  margin-bottom: 4.875rem !important; }

.u-m-b-114 {
  margin-bottom: 7.125rem !important; }

.u-m-b-156 {
  margin-bottom: 9.75rem !important; }

.u-m-b-204 {
  margin-bottom: 12.75rem !important; }

.R14 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  padding-top: 0.34375rem;
  margin-bottom: -0.34375rem; }

.Rb14 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  padding-top: 0.355rem;
  margin-bottom: -0.355rem; }

.OS14 {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  padding-top: 0.31875rem;
  margin-bottom: -0.31875rem; }

.OSb14 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  padding-top: 0.31875rem;
  margin-bottom: -0.31875rem; }

.R16 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.39375rem;
  margin-bottom: -0.39375em; }

.Rb16 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.39375em;
  margin-bottom: -0.39375em; }

.OS16 {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.35625rem;
  margin-bottom: -0.35625rem; }

.OSb16 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.35625rem;
  margin-bottom: -0.35625rem; }

.R20 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding-top: 0.49375rem;
  margin-bottom: -0.49375rem; }

.Rb20 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding-top: 0.49375rem;
  margin-bottom: -0.49375rem; }

.OS20 {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding-top: 0.44375rem;
  margin-bottom: -0.44375rem; }

.OSb20 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding-top: 0.44375rem;
  margin-bottom: -0.44375rem; }

.R25 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 1.5625rem;
  line-height: 2.25rem;
  padding-top: 0.56875rem;
  margin-bottom: -0.56875rem; }

.Rb25 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  padding-top: 0.38125rem;
  margin-bottom: -0.38125rem; }

.OS25 {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 1.5625rem;
  line-height: 2.25rem;
  padding-top: 0.51875rem;
  margin-bottom: -0.51875rem; }

.OSb25 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  padding-top: 0.33125rem;
  margin-bottom: -0.33125rem; }

.Rb31 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.9375rem;
  line-height: 2.25rem;
  padding-top: 0.44375rem;
  margin-bottom: -0.44375rem; }

.OSb31 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 1.9375rem;
  line-height: 2.25rem;
  padding-top: 0.375rem;
  margin-bottom: -0.375rem; }

.Rb39 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 2.4375rem;
  line-height: 3rem;
  padding-top: 0.64375rem;
  margin-bottom: -0.64375rem; }

.OSb39 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 2.4375rem;
  line-height: 3rem;
  padding-top: 0.55625rem;
  margin-bottom: -0.55625rem; }

.Rb48 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.375rem;
  padding-top: 0.63125rem;
  margin-bottom: -0.63125rem; }

.OSb48 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.375rem;
  padding-top: 0.525rem;
  margin-bottom: -0.525rem; }

.B-R14 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem; }

.B-Rb14 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.3125rem; }

.B-OS14 {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem; }

.B-OSb14 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.3125rem; }

.B-R16, .h4 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem; }

.B-Rb16 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem; }

.B-OS16, .main, .progkat .review__information .people p {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem; }

.B-OSb16 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem; }

.B-R20 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem; }

.B-Rb20, .section-selector-wrapper .learn-more, .section-selector .section-item, .system-message .animation-wrapper .content__btn {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.875rem; }

.B-OS20 {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem; }

.B-OSb20, .progkat .review__information .people__amount {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.875rem; }

.B-R25 {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 1.5625rem;
  line-height: 2.25rem; }

.B-Rb25, .h2, .article-card__title, .author-box .inner .name, .progkat__title {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.875rem; }

.B-OS25 {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 1.5625rem;
  line-height: 2.25rem; }

.B-OSb25 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.875rem; }

.B-Rb31, .h1, .h3, .header-wrapper .menu-item, .top-highlighted-article .highlighted-article__header .title, .section-highlighted-article .highlighted-article__header .title, .system-message .animation-wrapper__title, .system-message .animation-wrapper .content__title, .article__title .title, .welcome .title {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.9375rem;
  line-height: 2.25rem; }

.B-OSb31 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 1.9375rem;
  line-height: 2.25rem; }

.B-Rb39 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 2.4375rem;
  line-height: 3rem; }

.B-OSb39 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 2.4375rem;
  line-height: 3rem; }

.B-Rb48, .text-widget .quote {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.375rem; }

.B-OSb48 {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.375rem; }

:root {
  --whitish: #ffffff;
  --whitish-2: #f9f9f9;
  --cream: #f5f5f5;
  --dark-grey-1: #212121;
  --dark-grey-2: #424242;
  --dark-grey-3: #545454;
  --dark-grey-4: #595959;
  --dark-grey-5: #707070;
  --dark-grey-6: #777;
  --dark-grey-7: #949494;
  --dark-grey-8: #464646;
  --light-grey-0: #f5f5f5;
  --light-grey-1: #eeeeee;
  --light-grey-2: #eaeaea;
  --light-grey-3: #bdbdbd;
  --light-grey-4: #D8D8D8;
  --light-grey-5: #F3F3F3;
  --light-green: #D7F4BD;
  --green: #41c058;
  --green-2:#73A22C;
  --green-3:#5B8121;
  --green-4: #426200;
  --green-5: #81B532;
  --green-6: #95D13C;
  --green-7: #188291;
  --blue: #12A3B4;
  --blue-2: #00B6CB;
  --blue-3: #def1fb;
  --blue-4: #D1F0F7;
  --yellow: #ffc000;
  --yellow-faded: #ffbf0062;
  --orange-1: #FFD191;
  --orange-2: #E39D14;
  --backdrop: rgba(0, 0, 0, 0.5);
  --gutter: 1.25rem;
  --col-max-width: calc((73.75rem - 11 * var(--gutter)) / 12);
  --col2-max-width: calc((73.75rem - 1 * var(--gutter)) / 2);
  --col3-max-width: calc((73.75rem - 2 * var(--gutter)) / 3);
  --col4-max-width: calc((73.75rem - 3 * var(--gutter)) / 4);
  --fast: 0.2s;
  --slow: 0.5s;
  --very-slow: 0.7s;
  --small-scale: 1.04;
  --medium-scale: 1.08;
  --small-adjust: calc( ( var(--small-scale) - 1 ) / -2 * 100% );
  --col-4-11-grid: col-start 4 / col-end 11;
  --col-4-11-padding: 1.25rem 1.875rem;
  --col-5-11-grid: col-start 4 / col-end 11;
  --col-5-11-padding: 3rem 6.25rem 0rem 6.25rem;
  --margin-of-top: 3.85871em;
  --col-max-width: calc((1180px - 11 * var(--gutter)) / 12); }

@media only screen and (max-width: 65.625em) {
  :root {
    --col-4-11-grid: col-start 2 / col-end 11;
    --col-4-11-padding: 1.25rem 2.5rem;
    --col-5-11-grid: col-start 2 / col-end 11;
    --col-5-11-padding: 1.25rem 2.5rem 1.25rem 10%; } }

@media only screen and (max-width: 50em) {
  :root {
    --col-4-11-grid: main-start / main-end;
    --col-4-11-padding: 0.625rem 1.25rem;
    --col-5-11-grid: main-start / main-end;
    --col-5-11-padding: 2.5rem 1.25rem; } }

@media only screen and (max-width: 37.5em) {
  :root {
    --gutter: 0.9375rem;
    --col-5-11-grid: 1 / -1; } }

.h3, .header-wrapper .menu-item {
  font-size: 1.25rem;
  line-height: unset; }

.h4 {
  text-transform: uppercase; }

.f-h1 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.9375rem;
  line-height: 2.25rem;
  padding-top: 0.44375rem;
  margin-bottom: -0.44375rem;
  margin-top: calc(4.875rem - 2.25rem - 0.44375rem);
  color: var(--blackish); }

.f-h2 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  padding-top: 0.38125rem;
  margin-bottom: -0.38125rem;
  color: var(--blackish); }

.f-h3 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding-top: 0.49375rem;
  margin-bottom: -0.49375rem;
  color: var(--blackish); }

.p {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.35625rem;
  margin-bottom: -0.35625rem;
  margin-top: calc(3.375rem - 1.5rem - 0.35625rem); }

.bold {
  font-weight: bold;
  color: var(--blackish); }

a {
  color: #12A3B4;
  color: var(--blue);
  cursor: pointer; }

.label, .article-card__label, .top-highlighted-article .highlighted-article__header .labels, .section-highlighted-article .highlighted-article__header .labels, .article__title .label {
  color: var(--edl-color-gray60);
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif; }

strong {
  font-family: 'Open Sans', sans-serif; }

@media only screen and (max-width: 50em) {
  .h1, .h3, .header-wrapper .menu-item, .top-highlighted-article .highlighted-article__header .title, .section-highlighted-article .highlighted-article__header .title, .system-message .animation-wrapper__title, .system-message .animation-wrapper .content__title, .article__title .title {
    font-size: 1.375rem;
    line-height: unset; }
  .h2, .article-card__title, .author-box .inner .name, .progkat__title {
    font-size: 1.125rem;
    line-height: unset; }
  .h3, .header-wrapper .menu-item {
    font-size: 1.0625rem;
    line-height: unset; } }

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
            transform: translateX(-6.25rem); }
  80% {
    -webkit-transform: translateX(0.625rem);
            transform: translateX(0.625rem); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
            transform: translateX(-6.25rem); }
  80% {
    -webkit-transform: translateX(0.625rem);
            transform: translateX(0.625rem); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
            transform: translateX(6.25rem); }
  80% {
    -webkit-transform: translateX(-0.625rem);
            transform: translateX(-0.625rem); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
            transform: translateX(6.25rem); }
  80% {
    -webkit-transform: translateX(-0.625rem);
            transform: translateX(-0.625rem); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1.875rem);
            transform: translateY(1.875rem); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1.875rem);
            transform: translateY(1.875rem); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@-webkit-keyframes moveInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1.875rem);
            transform: translateY(1.875rem); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1.875rem);
            transform: translateY(1.875rem); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-37.5rem) scale(0.2);
            transform: translateX(-37.5rem) scale(0.2); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-37.5rem) scale(0.2);
            transform: translateX(-37.5rem) scale(0.2); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(37.5rem) scale(0.2);
            transform: translateX(37.5rem) scale(0.2); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(37.5rem) scale(0.2);
            transform: translateX(37.5rem) scale(0.2); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes slideOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-37.5rem) scale(0.2);
            transform: translateX(-37.5rem) scale(0.2); } }

@keyframes slideOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-37.5rem) scale(0.2);
            transform: translateX(-37.5rem) scale(0.2); } }

@-webkit-keyframes slideOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(37.5rem) scale(0.2);
            transform: translateX(37.5rem) scale(0.2); } }

@keyframes slideOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(37.5rem) scale(0.2);
            transform: translateX(37.5rem) scale(0.2); } }

@-webkit-keyframes ripple-wave {
  50% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0; }
  99% {
    -webkit-transform: scale(0.001, 0.001);
            transform: scale(0.001, 0.001);
    opacity: 0; }
  100% {
    -webkit-transform: scale(0.001, 0.001);
            transform: scale(0.001, 0.001);
    opacity: 1; } }

@keyframes ripple-wave {
  50% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0; }
  99% {
    -webkit-transform: scale(0.001, 0.001);
            transform: scale(0.001, 0.001);
    opacity: 0; }
  100% {
    -webkit-transform: scale(0.001, 0.001);
            transform: scale(0.001, 0.001);
    opacity: 1; } }

@-webkit-keyframes appear-then-disappear {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes appear-then-disappear {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

body {
  box-sizing: border-box;
  background-color: var(--body-bg); }

.main {
  color: #595959;
  color: var(--dark-grey-4);
  background-color: #f9f9f9;
  background-color: var(--whitish-2);
  width: 100%;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter);
  grid-row-gap: 0; }
  .main img {
    -webkit-user-select: none;
            user-select: none; }

.container-3 {
  grid-column: main-start / main-end;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(auto-fit, minmax(17.5rem, 1fr)); }
  @media only screen and (max-width: 37.5em) {
    .container-3 {
      grid-column: 1 / -1; } }

#apertus-edl-footer {
  margin-top: 7.125rem; }

.img-container {
  overflow: hidden;
  height: 22vw;
  min-height: 12.5rem;
  max-height: 26.25rem; }
  .img-container img {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%; }

.visibility__hidden {
  height: 0;
  visibility: hidden; }

.SocialMediaShareButton {
  border-radius: 50%; }
  .SocialMediaShareButton:focus {
    box-shadow: var(--edl-outline); }

.red {
  border: solid 1px red; }

.u-margin-left-medium {
  margin-left: 3.375rem !important; }
  @media only screen and (max-width: 65.625em) {
    .u-margin-left-medium {
      margin-left: 1.5rem !important; } }

.u-margin-right-medium {
  margin-right: 2.25rem !important; }
  @media only screen and (max-width: 65.625em) {
    .u-margin-right-medium {
      margin-right: .6rem !important; } }

.u-margin-top-small {
  margin-top: 0.75rem !important; }

.u-margin-top-small-2 {
  margin-top: 0.75rem !important; }

.u-m-t-m-h2 {
  margin-top: calc(4.875rem - 1.875rem - 0.38125rem); }

.u-m-t-s-h2 {
  margin-top: 2.5rem !important; }

.u-m-b-s-h2 {
  margin-bottom: 0.9375rem !important; }

.u-m-b-s {
  margin-bottom: 2.8125rem !important; }

.u-m-t-s {
  margin-top: calc(3.375rem - 1.875rem - 0.44375rem); }

.u-m-t-m {
  margin-top: 1.6875rem !important; }

.u-m-t-l {
  margin-top: 4.5625rem !important; }

.u-m-l-m {
  margin-left: 2.5rem !important; }

.u-m-t-12 {
  margin-top: 0.75rem !important; }

.u-m-l-a {
  width: calc(100% - 3.75rem) !important;
  margin-left: auto !important; }

p.u-m-t-s {
  margin-top: calc(2.25rem - 1.5rem - 0.35625rem); }

.u-m-t-0 {
  margin-top: 0 !important; }

.article-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.25rem;
  grid-column-gap: var(--gutter);
  background-color: #ffffff;
  background-color: var(--whitish);
  overflow: hidden;
  padding-bottom: 2rem;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s;
  transition: box-shadow var(--fast);
  border-radius: 6px; }
  .article-card:hover {
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2); }
  @media only screen and (max-width: 37.5em) {
    .article-card {
      border-radius: 0; } }
  .article-card > *:not(.article-card__image):not(.taglist) {
    padding: 0 1.25rem; }
  .article-card__image {
    width: 100%;
    height: 13.125rem;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    position: relative;
    margin-bottom: 1.3125rem;
    cursor: pointer;
    transition: all 0.5s;
    transition: all var(--slow);
    -webkit-user-select: none;
            user-select: none; }
    .article-card__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.7s;
      transition: all var(--very-slow); }
  .article-card__image.hover img, .article-card__image:hover img {
    -webkit-transform: scale(1.04) translateY(calc( ( 1.04 - 1 ) / -2 * 100% ));
            transform: scale(1.04) translateY(calc( ( 1.04 - 1 ) / -2 * 100% ));
    -webkit-transform: scale(var(--small-scale)) translateY(var(--small-adjust));
            transform: scale(var(--small-scale)) translateY(var(--small-adjust));
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .article-card__image:hover ~ .article-card__title {
    color: #5B8121;
    color: var(--green-3);
    text-decoration: underline; }
  .article-card__label {
    margin-bottom: 0.5625rem;
    line-height: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    @media only screen and (max-width: 37.5em) {
      .article-card__label {
        padding: 0 1.25rem; } }
  .article-card__title {
    color: #595959;
    color: var(--dark-grey-4);
    margin-bottom: 0.75rem;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    transition: 0.2s;
    transition: var(--fast);
    overflow: hidden; }
    @supports (-webkit-line-clamp: 2) and (display: -webkit-box) {
      .article-card__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* autoprefixer: off */
        -webkit-box-orient: vertical; } }
    .article-card__title:focus {
      box-shadow: var(--edl-btn-outline);
      outline: 2px solid var(--edl-color-white); }
    .article-card__title.hover, .article-card__title:hover, .article-card__title:focus {
      color: #5B8121;
      color: var(--green-3);
      text-decoration: underline; }
    .article-card__title h3 {
      font-size: 1.5625rem; }
    @media only screen and (max-width: 37.5em) {
      .article-card__title {
        padding: 0 1.25rem;
        grid-column: 1 / -1; } }
  .article-card .article-card__image.hover img {
    -webkit-transform: scale(1.04) translateY(calc( ( 1.04 - 1 ) / -2 * 100% ));
            transform: scale(1.04) translateY(calc( ( 1.04 - 1 ) / -2 * 100% ));
    -webkit-transform: scale(var(--small-scale)) translateY(var(--small-adjust));
            transform: scale(var(--small-scale)) translateY(var(--small-adjust));
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .article-card__text {
    margin-bottom: 1.125rem;
    overflow: hidden; }
    @supports (-webkit-line-clamp: 3) and (display: -webkit-box) {
      .article-card__text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* autoprefixer: off */
        -webkit-box-orient: vertical; } }
    @media only screen and (max-width: 37.5em) {
      .article-card__text {
        padding: 0 1.25rem; } }
  .article-card__tags {
    margin-bottom: 0.75rem; }
    .article-card__tags .tag {
      margin-bottom: 0.75rem; }
    @media only screen and (max-width: 37.5em) {
      .article-card__tags {
        padding: 0 1.25rem; } }

.article-card.horizontal {
  grid-template-columns: 17.5rem 1fr;
  grid-template-rows: -webkit-min-content -webkit-min-content 1fr;
  grid-template-rows: min-content min-content 1fr;
  height: 13.125rem;
  -webkit-animation: fadeIn 0.7s;
          animation: fadeIn 0.7s;
  -webkit-animation: fadeIn var(--very-slow);
          animation: fadeIn var(--very-slow); }
  .article-card.horizontal > * {
    padding-left: 0; }
  .article-card.horizontal .article-card__image {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    margin-bottom: 1.3125rem; }
  .article-card.horizontal .article-card__label {
    padding-top: 1.125rem; }
    @media only screen and (max-width: 65.625em) {
      .article-card.horizontal .article-card__label {
        padding-bottom: 0 !important;
        grid-row: 2;
        margin-top: 0 !important; } }
  .article-card.horizontal .article-card__text {
    margin-bottom: 0;
    -webkit-align-self: start;
            align-self: start;
    padding-bottom: 0 !important;
    overflow: hidden; }
    @supports (-webkit-line-clamp: 3) and (display: -webkit-box) {
      .article-card.horizontal .article-card__text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* autoprefixer: off */
        -webkit-box-orient: vertical; } }
    @media only screen and (max-width: 65.625em) {
      .article-card.horizontal .article-card__text {
        grid-row: 4; } }
  .article-card.horizontal .article-card__title {
    color: #595959;
    color: var(--dark-grey-4); }
    .article-card.horizontal .article-card__title.hover {
      color: #5B8121;
      color: var(--green-3); }
    @media only screen and (max-width: 65.625em) {
      .article-card.horizontal .article-card__title {
        grid-row: 3 !important;
        padding-bottom: 0 !important; } }
  .article-card.horizontal .article-card__tags {
    display: none; }
  .article-card.horizontal .article-card__image.hover img {
    -webkit-transform: scale(1.04) translateY(0%) translateX(calc( ( 1.04 - 1 ) / -2 * 100% ));
            transform: scale(1.04) translateY(0%) translateX(calc( ( 1.04 - 1 ) / -2 * 100% ));
    -webkit-transform: scale(var(--small-scale)) translateY(0%) translateX(var(--small-adjust));
            transform: scale(var(--small-scale)) translateY(0%) translateX(var(--small-adjust)); }
    @media only screen and (max-width: 65.625em) {
      .article-card.horizontal .article-card__image.hover img {
        -webkit-transform: scale(1.04) translateY(calc( ( 1.04 - 1 ) / -2 * 100% )) translateX(0%);
                transform: scale(1.04) translateY(calc( ( 1.04 - 1 ) / -2 * 100% )) translateX(0%);
        -webkit-transform: scale(var(--small-scale)) translateY(var(--small-adjust)) translateX(0%);
                transform: scale(var(--small-scale)) translateY(var(--small-adjust)) translateX(0%); } }
  .article-card.horizontal .article-card__title {
    grid-row: 2; }
    @media only screen and (max-width: 50em) {
      .article-card.horizontal .article-card__title {
        grid-row: 3; } }
  .article-card.horizontal .article-card__image.hover img {
    -webkit-transform: scale(1.04) translateY(0%) translateX(calc( ( 1.04 - 1 ) / -2 * 100% ));
            transform: scale(1.04) translateY(0%) translateX(calc( ( 1.04 - 1 ) / -2 * 100% ));
    -webkit-transform: scale(var(--small-scale)) translateY(0%) translateX(var(--small-adjust));
            transform: scale(var(--small-scale)) translateY(0%) translateX(var(--small-adjust)); }
    @media only screen and (max-width: 65.625em) {
      .article-card.horizontal .article-card__image.hover img {
        -webkit-transform: scale(1.04) translateY(calc( ( 1.04 - 1 ) / -2 * 100% )) translateX(0%);
                transform: scale(1.04) translateY(calc( ( 1.04 - 1 ) / -2 * 100% )) translateX(0%);
        -webkit-transform: scale(var(--small-scale)) translateY(var(--small-adjust)) translateX(0%);
                transform: scale(var(--small-scale)) translateY(var(--small-adjust)) translateX(0%); } }
  @media only screen and (max-width: 65.625em) {
    .article-card.horizontal {
      grid-template-columns: unset;
      grid-template-rows: unset;
      height: unset;
      padding-bottom: 1.125rem; }
      .article-card.horizontal > *:not(.article-card__image) {
        padding: 0 1.25rem 1.125rem 1.25rem; }
      .article-card.horizontal .article-card__image {
        grid-column: unset;
        grid-row: unset; }
      .article-card.horizontal .article-card__label {
        margin-top: 0.75rem; }
      .article-card.horizontal .article-card__tags {
        display: none; } }

.button, .section-selector-wrapper .section-title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none !important; }
  .button--green, .section-selector-wrapper .section-title {
    height: 2.8125rem;
    border: 2px solid #73A22C;
    border: 2px solid var(--green-2);
    border-radius: 1.875rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
    .button--green span, .section-selector-wrapper .section-title span {
      margin-right: 0.625rem;
      color: #73A22C;
      color: var(--green-2);
      font-size: 1.125rem; }
  .button--blue {
    background-color: #00B6CB;
    background-color: var(--blue-2);
    color: white;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    font-weight: bold; }
  .button--borrad-6 {
    border-radius: 0.375rem;
    padding: 0.5625rem 0.9375rem; }
  .button--borrad-21 {
    border-radius: 1.3125rem;
    padding: 0.6875rem 1.875rem; }
  .button:focus, .section-selector-wrapper .section-title:focus {
    box-shadow: var(--edl-btn-outline);
    outline: 2px solid var(--edl-color-white); }

.header-wrapper {
  grid-column: 1 / -1;
  background-color: white;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter); }
  .header-wrapper .header {
    grid-column: main-start / main-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .header-wrapper .product-related,
  .header-wrapper .user-related {
    display: -webkit-flex;
    display: flex; }
  .header-wrapper .logo {
    width: 15.625rem;
    height: 5rem;
    overflow: hidden;
    padding: 0 1.25rem; }
    .header-wrapper .logo img {
      width: 100%;
      opacity: 0.8;
      -webkit-transform: translateY(calc(-50% + 2.5rem)) rotate(-15deg);
              transform: translateY(calc(-50% + 2.5rem)) rotate(-15deg); }
  .header-wrapper .menu-item {
    padding: 1.875rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 300;
    color: #595959;
    color: var(--dark-grey-4);
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .header-wrapper .menu-item.login::before {
      content: '';
      position: absolute;
      top: 1.25rem;
      bottom: 1.25rem;
      left: 0;
      width: 5px;
      transition: all 0.5s;
      transition: all var(--slow);
      background-color: #41c058;
      background-color: var(--green); }
    .header-wrapper .menu-item.register::before {
      content: '';
      position: absolute;
      top: 1.25rem;
      bottom: 1.25rem;
      left: 0;
      width: 5px;
      transition: all 0.5s;
      transition: all var(--slow);
      background-color: #12A3B4;
      background-color: var(--blue); }
    .header-wrapper .menu-item.selected::before {
      content: '';
      position: absolute;
      left: 1.25rem;
      right: 1.25rem;
      bottom: 0;
      height: 0.3125rem;
      background-color: #41c058;
      background-color: var(--green); }
    .header-wrapper .menu-item.bold {
      font-weight: bold; }

.welcome {
  grid-column: main-start / main-end;
  background-color: #f5f5f5;
  background-color: var(--light-grey-0);
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s;
  transition: box-shadow var(--fast);
  padding: 1.875em 1.25rem;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 2.25rem;
  margin-bottom: 3.375rem;
  z-index: 1; }
  .welcome .title,
  .welcome .btn,
  .welcome .text {
    margin-bottom: 1.25rem;
    margin-bottom: var(--gutter);
    width: 100%;
    max-width: 48.75rem;
    -webkit-flex-grow: 0;
            flex-grow: 0; }
  .welcome .text {
    font-size: 1.25rem;
    line-height: 1.875rem; }
  .welcome .btn {
    max-width: 23.75rem; }
  .welcome .or-login {
    font-size: 1rem;
    color: #bdbdbd;
    color: var(--light-grey-3); }

.tag {
  outline: none;
  border: none;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  background-color: #eeeeee;
  background-color: var(--light-grey-1);
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.4375rem 1.25rem;
  border-radius: 1.25rem;
  color: #595959;
  color: var(--dark-grey-4);
  margin-bottom: 0.75rem;
  margin-right: calc(1.25rem / 2);
  margin-right: calc(var(--gutter) / 2);
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }
  .tag:hover {
    background-color: #5B8121 !important;
    background-color: var(--green-3) !important;
    color: white; }

/*
.section-title {
    grid-row: 1 / 2;
    grid-column: main-start / main-end !important;
    align-self: start;
    justify-self: start;
    z-index: 100;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    color: var(--dark-grey-4);
    text-decoration: unset;
    @extend .button;
    @extend.button--green;
    @extend .h2;
    @include shadow-hover;
    transition: all var(--fast);
    user-select: none;

    span {
        transition: all var(--fast);
    }

    &:hover {
        background-color: var(--green-3);
        color:white;
        border: solid transparent 2px;

        span {
            color: white;
        }
    }

    @include media(phone) {
        justify-self: center;
    }
}
 */
.top-highlighted-article, .section-highlighted-article {
  grid-column: 1 / -1;
  z-index: 1;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter);
  grid-row-gap: 0;
  transition: box-shadow 0.5s;
  transition: box-shadow var(--slow);
  z-index: auto !important; }
  .top-highlighted-article .highlighted-article__cover, .section-highlighted-article .highlighted-article__cover {
    grid-column: 1 / -1;
    grid-row: 1 / 3;
    min-height: 21.875rem;
    height: 30vw;
    max-height: 37.5rem;
    width: 100%;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none; }
    .top-highlighted-article .highlighted-article__cover img, .section-highlighted-article .highlighted-article__cover img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%; }
  .top-highlighted-article .highlighted-article__header, .section-highlighted-article .highlighted-article__header {
    grid-row: 2 / 3;
    grid-column: col-start 4 / col-end 11;
    align-self: end;
    background-color: white;
    position: relative;
    top: 2px;
    border-radius: 0.375rem 0.375rem 0 0;
    z-index: auto;
    display: grid;
    grid-template-columns: repeat(8, [col-start] 1fr [col-end]);
    grid-row-gap: 0.875rem;
    grid-column-gap: 1.25rem;
    grid-column-gap: var(--gutter);
    -webkit-animation: fadeIn 0.5s;
            animation: fadeIn 0.5s;
    -webkit-animation: fadeIn var(--slow);
            animation: fadeIn var(--slow); }
    .top-highlighted-article .highlighted-article__header::before, .section-highlighted-article .highlighted-article__header::before {
      box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.2s;
      transition: box-shadow var(--fast);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      transition: box-shadow 0.5s;
      transition: box-shadow var(--slow); }
    .top-highlighted-article .highlighted-article__header.shadow::before, .section-highlighted-article .highlighted-article__header.shadow::before {
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2); }
    @media only screen and (max-width: 37.5em) {
      .top-highlighted-article .highlighted-article__header, .section-highlighted-article .highlighted-article__header {
        border-radius: 0; } }
    .top-highlighted-article .highlighted-article__header .labels, .section-highlighted-article .highlighted-article__header .labels {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      padding: 1.25rem 1.875rem 0;
      grid-row: 1 / 2;
      grid-column: 1 / -1; }
    .top-highlighted-article .highlighted-article__header .author, .section-highlighted-article .highlighted-article__header .author {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
      color: #12A3B4;
      color: var(--blue);
      z-index: 11;
      font-style: italic;
      justify-self: end;
      text-transform: capitalize; }
      .top-highlighted-article .highlighted-article__header .author:hover, .section-highlighted-article .highlighted-article__header .author:hover {
        text-decoration: underline;
        color: #188291;
        color: var(--green-7); }
    .top-highlighted-article .highlighted-article__header .title, .section-highlighted-article .highlighted-article__header .title {
      padding-left: 1.875rem;
      grid-column: col-start 1 / -2;
      grid-row: 2 / 3;
      align-self: end;
      z-index: 11;
      cursor: pointer;
      color: #595959;
      color: var(--dark-grey-4);
      overflow: hidden; }
      @supports (-webkit-line-clamp: 2) and (display: -webkit-box) {
        .top-highlighted-article .highlighted-article__header .title, .section-highlighted-article .highlighted-article__header .title {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* autoprefixer: off */
          -webkit-box-orient: vertical; } }
      .top-highlighted-article .highlighted-article__header .title:hover, .section-highlighted-article .highlighted-article__header .title:hover {
        color: #5B8121;
        color: var(--green-3);
        text-decoration: underline; }
      .top-highlighted-article .highlighted-article__header .title.hover, .section-highlighted-article .highlighted-article__header .title.hover {
        color: #5B8121;
        color: var(--green-3);
        text-decoration: underline; }
      @supports not (-webkit-line-clamp: 3) {
        .top-highlighted-article .highlighted-article__header .title, .section-highlighted-article .highlighted-article__header .title {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap; } }
  .top-highlighted-article .highlighted-article__body-wrapper, .section-highlighted-article .highlighted-article__body-wrapper {
    grid-column: 1 / -1;
    position: relative;
    display: grid;
    grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
    grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    grid-gap: 1.25rem;
    grid-gap: var(--gutter);
    grid-row-gap: 0;
    position: relative;
    z-index: 5; }
    .top-highlighted-article .highlighted-article__body-wrapper.overflow-hidden, .section-highlighted-article .highlighted-article__body-wrapper.overflow-hidden {
      overflow: hidden; }
    .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body {
      position: relative;
      border-radius: 0 0 0.375rem 0.375rem;
      grid-row: 3 / 4;
      grid-column: col-start 4 / col-end 11;
      align-self: start;
      background-color: #ffffff;
      background-color: var(--whitish);
      margin-bottom: 3rem;
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      transition: box-shadow 0.2s;
      transition: box-shadow var(--fast);
      -webkit-animation: fadeIn 0.5s;
              animation: fadeIn 0.5s;
      -webkit-animation: fadeIn var(--slow);
              animation: fadeIn var(--slow);
      z-index: auto;
      display: grid;
      grid-template-columns: repeat(8, [col-start] 1fr [col-end]);
      grid-column-gap: 1.25rem;
      grid-column-gap: var(--gutter); }
      .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body::before, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body::before {
        box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
        transition: box-shadow 0.2s;
        transition: box-shadow var(--fast);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1; }
      .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body.shadow::before, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body.shadow::before {
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2); }
      @media only screen and (max-width: 37.5em) {
        .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body {
          border-radius: 0; } }
      .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .text, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .text {
        margin: 0.75rem 1.875rem 1.125rem;
        grid-column: col-start 1 / -2;
        grid-row: 1; }
      .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .tags, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .tags {
        margin: 0 1.875rem 2.625rem;
        grid-column: 1 / -1;
        margin-bottom: 0.875rem;
        z-index: 11; }
  .top-highlighted-article .arrow, .section-highlighted-article .arrow {
    grid-column: -2 / -1;
    align-self: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 3.125rem;
    height: 3.125rem;
    border: 0px;
    border-radius: 2.5rem;
    background-position: center;
    background-color: inherit;
    transition: background 0.2s, color 0.2s;
    transition: background var(--fast), color var(--fast);
    font-size: 1.875rem;
    color: #eaeaea;
    color: var(--light-grey-2);
    cursor: pointer;
    position: relative;
    overflow: hidden; }
    .top-highlighted-article .arrow:before, .section-highlighted-article .arrow:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      display: block;
      position: absolute;
      height: 3.125rem;
      width: 3.125rem;
      -webkit-transform: scale(0.001, 0.001);
              transform: scale(0.001, 0.001); }
    .top-highlighted-article .arrow:focus, .section-highlighted-article .arrow:focus {
      outline: 0; }
      .top-highlighted-article .arrow:focus:before, .section-highlighted-article .arrow:focus:before {
        -webkit-animation: ripple-wave 0.5s ease-out;
                animation: ripple-wave 0.5s ease-out; }
    .top-highlighted-article .arrow.hover, .section-highlighted-article .arrow.hover {
      color: #426200;
      color: var(--green-4);
      background: #D7F4BD radial-gradient(circle, transparent 1%, #D7F4BD 1%) center/15000%;
      background: var(--light-green) radial-gradient(circle, transparent 1%, var(--light-green) 1%) center/15000%; }

@media only screen and (max-width: 65.625em) {
  .top-highlighted-article .highlighted-article__header, .section-highlighted-article .highlighted-article__header, .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body {
    grid-column: col-start 2 / col-end 11; } }

@media only screen and (max-width: 50em) {
  .top-highlighted-article .highlighted-article__header, .section-highlighted-article .highlighted-article__header, .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body {
    grid-column: main-start / main-end; }
  .top-highlighted-article .highlighted-article__header, .section-highlighted-article .highlighted-article__header {
    padding: 0.625rem 1.25rem 0 1.25rem;
    grid-template-columns: 1fr;
    grid-row-gap: 0.625rem; }
    .top-highlighted-article .highlighted-article__header .label, .section-highlighted-article .highlighted-article__header .label, .top-highlighted-article .highlighted-article__header .article-card__label, .section-highlighted-article .highlighted-article__header .article-card__label, .top-highlighted-article .highlighted-article__header .labels, .section-highlighted-article .highlighted-article__header .labels {
      padding: 0.625rem 0.625rem 0 0; }
    .top-highlighted-article .highlighted-article__header .title, .section-highlighted-article .highlighted-article__header .title {
      grid-column: 1 / 2;
      grid-row: auto;
      padding: 0; }
  .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body {
    margin-bottom: 3.125rem;
    padding: 0.625rem 1.25rem; }
    .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .arrow, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .arrow {
      display: none; }
    .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .text, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .text {
      grid-column: 1 / -1;
      margin-left: 0;
      margin-right: 0; }
    .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .tags, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body .tags {
      margin-left: 0;
      margin-right: 0; } }

@media only screen and (max-width: 37.5em) {
  .top-highlighted-article .highlighted-article__header, .section-highlighted-article .highlighted-article__header, .top-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body, .section-highlighted-article .highlighted-article__body-wrapper .highlighted-article__body {
    grid-column: 1 / -1; } }

.top-articles {
  grid-column: 1 / -1;
  position: relative;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter); }
  .top-articles .card-holder {
    z-index: 1; }
    @media only screen and (max-width: 37.5em) {
      .top-articles .card-holder {
        grid-column: 1 / -1; } }
    .top-articles .card-holder .article-card__image {
      height: 21vw;
      min-height: 18.75rem;
      height: 26.25rem; }
  .top-articles::before {
    position: absolute;
    display: block;
    background-color: #F3F3F3;
    background-color: var(--light-grey-5);
    content: '';
    height: 26.25rem;
    width: 100%;
    z-index: 0;
    top: -7.5rem; }

.section-highlighted-article {
  margin-top: 9.75rem;
  position: relative; }
  .section-highlighted-article .section-title {
    margin-top: 1.125rem; }
  .section-highlighted-article .highlighted-article__header {
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    top: unset; }
  .section-highlighted-article .highlighted-article__body-container {
    grid-column: 1 / -1;
    position: relative;
    display: grid;
    grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
    grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    grid-gap: 1.25rem;
    grid-gap: var(--gutter);
    grid-row-gap: 0;
    position: relative;
    z-index: 5; }
    .section-highlighted-article .highlighted-article__body-container::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 22.25rem;
      z-index: -1;
      background-color: #eeeeee;
      background-color: var(--light-grey-1); }

.section-articles {
  grid-column: 1 / -1;
  z-index: 10;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter); }
  .section-articles .card-holder {
    z-index: 1; }
    @media only screen and (max-width: 37.5em) {
      .section-articles .card-holder {
        grid-column: 1 / -1; } }

.section-selector-wrapper {
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter);
  grid-row-gap: 0;
  grid-column: 1 / -1;
  grid-template-rows: repeat(4, -webkit-min-content);
  grid-template-rows: repeat(4, min-content); }
  @media only screen and (max-width: 50em) {
    .section-selector-wrapper > *:not(.learn-more) {
      display: none; } }
  .section-selector-wrapper .section-title {
    margin-top: 7.125rem;
    margin-bottom: 1.125rem;
    justify-self: start;
    -webkit-align-self: center;
            align-self: center;
    text-decoration: unset; }
  .section-selector-wrapper .card-holder {
    grid-column: main-start / col-end 8;
    margin-bottom: 1.125rem; }
    .section-selector-wrapper .card-holder:nth-last-of-type(1) {
      margin-bottom: 0; }
    @media only screen and (max-width: 37.5em) {
      .section-selector-wrapper .card-holder {
        display: none; } }
  .section-selector-wrapper aside {
    grid-column: col-start 9/main-end;
    grid-row: 2 / -1; }
  .section-selector-wrapper__highlight:hover {
    text-decoration: underline; }
  .section-selector-wrapper .learn-more {
    cursor: pointer;
    background: none;
    border: none;
    outline: inherit;
    grid-column: main-start / col-end 8;
    grid-column: 10 / 14;
    grid-row: 4 / 5;
    padding-bottom: 0.75rem;
    color: #12A3B4;
    color: var(--blue);
    text-align: right;
    align-self: end;
    text-transform: uppercase;
    z-index: 5;
    -webkit-user-select: none;
            user-select: none; }
    @media only screen and (max-width: 50em) {
      .section-selector-wrapper .learn-more {
        font-size: 1rem;
        grid-column: main-start / main-end;
        margin-top: 3.375rem; } }
    .section-selector-wrapper .learn-more span {
      margin-left: 0.75rem;
      font-size: 1.25rem; }
      @media only screen and (max-width: 65.625em) {
        .section-selector-wrapper .learn-more span {
          font-size: 1rem; } }
    .section-selector-wrapper .learn-more:hover {
      text-decoration: underline;
      color: #188291;
      color: var(--green-7); }
  .section-selector-wrapper .section-selector {
    grid-row: 2 / 5;
    grid-column: col-start 9 / main-end; }
    @media only screen and (max-width: 37.5em) {
      .section-selector-wrapper .section-selector {
        grid-column: col-start 1/col-end 12; } }

.section-selector {
  margin-bottom: 2.25rem;
  -webkit-user-select: none;
          user-select: none;
  overflow: hidden;
  position: relative; }
  .section-selector::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-left: 0.3125rem solid;
    border-right: 0;
    border-image: linear-gradient(to bottom, #f9f9f9 0.625rem, #D7F4BD, #D7F4BD, #D7F4BD, #f9f9f9) 1 100%;
    border-image: linear-gradient(to bottom, var(--whitish-2) 0.625rem, var(--light-green), var(--light-green), var(--light-green), var(--whitish-2)) 1 100%; }
  .section-selector .section-item {
    background: none;
    border: none;
    outline: inherit;
    text-align: left;
    position: relative;
    color: #555555;
    cursor: pointer;
    display: block;
    margin-left: -5px;
    width: 100%;
    height: 3.375rem;
    padding: 0.78125em 2.1875em;
    overflow: hidden;
    z-index: 1; }
    @media only screen and (max-width: 65.625em) {
      .section-selector .section-item {
        padding: 0.78125rem 0.9375rem; } }
    @media only screen and (max-width: 37.5em) {
      .section-selector .section-item {
        background-image: unset;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        grid-column: main-start / main-end; } }
    .section-selector .section-item::before {
      display: block;
      content: "";
      background-color: #D7F4BD;
      background-color: var(--light-green);
      position: absolute;
      left: -100%;
      top: 0;
      transition: left 0.2s;
      transition: left var(--fast);
      height: 100%;
      width: 100%;
      border-radius: 0 1.6875rem 1.6875rem 0;
      z-index: -1; }
    .section-selector .section-item:hover {
      text-decoration: underline; }
    .section-selector .section-item.selected, .section-selector .section-item:hover {
      color: #426200;
      color: var(--green-4); }
    .section-selector .section-item.selected {
      padding-left: 2.8125rem; }
      .section-selector .section-item.selected:hover {
        text-decoration: none; }
      .section-selector .section-item.selected::before {
        left: 0; }

.text-widget {
  grid-column: col-start 4 / col-end 11;
  grid-column: var(--col-5-11-grid);
  padding: 3rem 6.25rem 0rem 6.25rem;
  padding: var(--col-5-11-padding);
  background-color: #ffffff;
  background-color: var(--whitish);
  position: relative;
  -webkit-animation: fadeIn 0.5s;
          animation: fadeIn 0.5s;
  -webkit-animation: fadeIn var(--slow);
          animation: fadeIn var(--slow); }
  .text-widget .iframe {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    margin-top: 2.25rem; }
  .text-widget iframe:not(.ql-video) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
  .text-widget iframe.ql-video {
    display: block;
    margin: 0 auto;
    width: 500px;
    height: 282px; }
  .text-widget h2 {
    margin-bottom: 1.125rem;
    font-family: Roboto, sans-serif;
    line-height: 1.875rem;
    font-size: 1.5625rem; }
  .text-widget .quote {
    color: #464646;
    color: var(--dark-grey-8);
    font-style: italic;
    position: relative;
    margin-top: 5.125rem;
    margin-left: 6.25rem;
    z-index: 1;
    min-height: 7.5rem; }
    @media only screen and (max-width: 37.5em) {
      .text-widget .quote {
        margin-left: 0; } }
    .text-widget .quote::before {
      font-style: normal;
      font-family: FontAwesome;
      content: '\F10D';
      display: block;
      font-size: 15rem;
      color: #eaeaea;
      color: var(--light-grey-2);
      position: absolute;
      top: 0;
      left: -6.25rem;
      z-index: -1; }
      @media only screen and (max-width: 37.5em) {
        .text-widget .quote::before {
          left: 0; } }
  .text-widget .highlight {
    text-align: center;
    color: #73A22C;
    color: var(--green-2);
    font-size: 1.9375rem;
    line-height: 2.25rem;
    font-style: italic;
    font-weight: bold; }
  .text-widget .indent {
    margin-left: 2.5rem;
    position: relative; }
    @media only screen and (max-width: 37.5em) {
      .text-widget .indent {
        margin-left: 1.25rem; } }
    .text-widget .indent::after {
      position: absolute;
      left: -2.5rem;
      content: '';
      display: block;
      height: 100%;
      background-color: #D7F4BD;
      background-color: var(--light-green);
      width: 0.3125rem;
      top: 0; }
      @media only screen and (max-width: 37.5em) {
        .text-widget .indent::after {
          left: -1.25rem; } }
  .text-widget .text-separator {
    height: 0.375rem;
    width: 11.25rem;
    background-color: #D7F4BD;
    background-color: var(--light-green);
    margin: 0 auto;
    margin-top: 3.9375rem; }
  .text-widget .normal img {
    object-fit: cover;
    width: 100%; }

.article__social-media {
  grid-column: col-start 3/col-end 3;
  grid-row: 2 / 4;
  align-self: start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 3.625rem;
  padding: 0.75rem 3rem 6.25rem 0rem 6.25rem;
  padding: 0.75rem var(--col-5-11-padding);
  -webkit-animation: fadeIn 0.5s;
          animation: fadeIn 0.5s;
  -webkit-animation: fadeIn var(--slow);
          animation: fadeIn var(--slow);
  position: absolute; }
  .article__social-media button {
    background-color: transparent; }
  @media only screen and (max-width: 65.625em) {
    .article__social-media {
      margin-top: 0;
      -webkit-flex-direction: row;
              flex-direction: row;
      position: unset; } }
  .article__social-media .clip-board-success {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 3rem;
    bottom: 10vh;
    border-radius: 1.5rem;
    padding: 0rem 1.25rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
    z-index: 1100;
    -webkit-animation: appear-then-disappear 5s forwards;
            animation: appear-then-disappear 5s forwards; }
  .article__social-media input {
    position: absolute;
    bottom: 100%;
    opacity: 0;
    z-index: -1000;
    pointer-events: none;
    right: 100%; }
  .article__social-media > * {
    display: -webkit-flex;
    display: flex;
    padding: 0.75rem 0.875rem;
    font-size: 1.875rem;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: #D8D8D8;
    color: var(--light-grey-4);
    cursor: pointer; }
    .article__social-media > *:hover {
      color: #595959;
      color: var(--dark-grey-4); }
  @media only screen and (max-width: 65.625em) {
    .article__social-media {
      grid-row: unset;
      grid-column: 3 / -2; } }
  @media only screen and (max-width: 50em) {
    .article__social-media {
      grid-column: col-start 4 / col-end 11;
      grid-column: var(--col-5-11-grid); } }

.author-box {
  grid-column: 1 / -1;
  background-color: #ffffff;
  background-color: var(--whitish); }
  .author-box .inner {
    background-color: #f5f5f5;
    background-color: var(--light-grey-0);
    border: 1px solid #eaeaea;
    border: 1px solid var(--light-grey-2);
    border-radius: 6px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    padding: 1.875rem; }
    @media only screen and (max-width: 37.5em) {
      .author-box .inner {
        border-radius: 0; } }
    @media only screen and (max-width: 50em) {
      .author-box .inner {
        display: block; } }
    .author-box .inner__image {
      cursor: pointer;
      border-radius: 50%;
      width: 8.75rem;
      height: 8.75rem;
      overflow: hidden;
      -webkit-align-self: center;
              align-self: center;
      -webkit-user-select: none;
              user-select: none;
      z-index: 100; }
      @media only screen and (max-width: 50em) {
        .author-box .inner__image {
          margin: 0 auto; } }
      .author-box .inner__image img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.5s;
        transition: all var(--slow);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden; }
        .author-box .inner__image img:hover {
          -webkit-transform: scale(1.08);
                  transform: scale(1.08);
          -webkit-transform: scale(var(--medium-scale));
                  transform: scale(var(--medium-scale)); }
    .author-box .inner img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 0.5s;
      transition: all var(--slow); }
      .author-box .inner img:hover {
        -webkit-transform: scale(1.08);
                transform: scale(1.08);
        -webkit-transform: scale(var(--medium-scale));
                transform: scale(var(--medium-scale)); }
    .author-box .inner .text {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      max-width: calc(100% - 11.25rem); }
      @media only screen and (max-width: 50em) {
        .author-box .inner .text {
          max-width: 100%; } }
      .author-box .inner .text p:not(:last-child) {
        margin-bottom: 0.75rem; }
    .author-box .inner .name {
      margin-bottom: 0.75rem;
      cursor: pointer;
      color: var(--edl-color-aqua60); }

.tags-filter .selected-tags {
  margin-bottom: 1.25rem;
  margin-bottom: var(--gutter); }
  .tags-filter .selected-tags .tag {
    outline: none;
    border: none;
    background-color: #5B8121;
    background-color: var(--green-3);
    color: white;
    -webkit-animation: NewTag 0.2s;
            animation: NewTag 0.2s;
    -webkit-animation: NewTag var(--fast);
            animation: NewTag var(--fast);
    padding: 0 0 0 1.25rem; }
    .tags-filter .selected-tags .tag:hover > .fa-close {
      opacity: 1; }
    .tags-filter .selected-tags .tag .fa-close {
      opacity: 0.8;
      padding: 0.5rem 1.25rem 0.5rem 0.9375rem;
      line-height: 1.5rem;
      cursor: pointer; }
      .tags-filter .selected-tags .tag .fa-close::before {
        content: "\F00D"; }

@-webkit-keyframes NewTag {
  0% {
    -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); }
  100% {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); } }

@keyframes NewTag {
  0% {
    -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); }
  100% {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); } }

/*
.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: var(--gutter);
    
    color: var(--light-grey-3);
    @extend .Rb20;

    & > * {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $s36;
        width: $s36;
        cursor: pointer;

        &:hover,
        &.selected {
            color: var(--green-3);
            background-color: var(--light-green);
            border-radius: 50%;
        }

        &.disabled {
            color: var(--light-grey-1);
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}
*/
.system-message {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  background-color: #f9f9f9;
  background-color: var(--whitish-2);
  height: 18.75rem;
  color: #545454;
  color: var(--dark-grey-3);
  grid-column: main-start/main-end;
  overflow: hidden;
  z-index: 1;
  border: 1px solid #D8D8D8;
  border: 1px solid var(--light-grey-4);
  border-radius: 6px;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s;
  transition: box-shadow var(--fast); }
  @media only screen and (max-width: 37.5em) {
    .system-message {
      border-radius: 0; } }
  @media only screen and (max-width: 37.5em) {
    .system-message {
      grid-column: full-start / full-end;
      height: 25rem;
      border-radius: 0; } }
  .system-message .animation-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    @media only screen and (max-width: 65.625em) {
      .system-message .animation-wrapper {
        max-width: calc(100% - 9rem);
        left: 4.5rem; } }
    .system-message .animation-wrapper__title {
      margin-bottom: 0.5rem; }
    .system-message .animation-wrapper--animation-slide-in-right {
      -webkit-animation: slideInRight 0.5s;
              animation: slideInRight 0.5s;
      -webkit-animation: slideInRight var(--slow);
              animation: slideInRight var(--slow); }
    .system-message .animation-wrapper--animation-slide-in-left {
      -webkit-animation: slideInLeft 0.5s;
              animation: slideInLeft 0.5s;
      -webkit-animation: slideInLeft var(--slow);
              animation: slideInLeft var(--slow); }
    .system-message .animation-wrapper--animation-slide-out-right {
      -webkit-animation: slideOutRight 0.5s forwards;
              animation: slideOutRight 0.5s forwards;
      -webkit-animation: slideOutRight var(--slow) forwards;
              animation: slideOutRight var(--slow) forwards; }
    .system-message .animation-wrapper--animation-slide-out-left {
      -webkit-animation: slideOutLeft 0.5s forwards;
              animation: slideOutLeft 0.5s forwards;
      -webkit-animation: slideOutLeft var(--slow) forwards;
              animation: slideOutLeft var(--slow) forwards; }
    .system-message .animation-wrapper .content {
      max-width: 48.75rem;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 100%;
      overflow-y: auto;
      padding: 20px 0;
      -webkit-justify-content: unset;
              justify-content: unset; }
      .system-message .animation-wrapper .content__title {
        margin-bottom: 0.5rem; }
      .system-message .animation-wrapper .content > *:not(.content__title):not(:last-child) {
        margin-bottom: 1.125rem; }
      .system-message .animation-wrapper .content__title {
        text-align: center; }
      @media only screen and (max-width: 37.5em) {
        .system-message .animation-wrapper .content p {
          text-align: center; } }
      .system-message .animation-wrapper .content p a:hover {
        text-decoration: underline; }
      .system-message .animation-wrapper .content__btn {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        position: relative;
        overflow: hidden;
        width: 23.75rem;
        height: 3rem;
        background-color: #73A22C;
        background-color: var(--green-2);
        border-radius: 1.5rem;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s;
        transition: all var(--fast);
        -webkit-user-select: none;
                user-select: none; }
        .system-message .animation-wrapper .content__btn:before {
          content: '';
          background-color: rgba(255, 255, 255, 0.4);
          border-radius: 50%;
          display: block;
          position: absolute;
          height: 23.75rem;
          width: 23.75rem;
          -webkit-transform: scale(0.001, 0.001);
                  transform: scale(0.001, 0.001); }
        .system-message .animation-wrapper .content__btn:focus {
          outline: 0; }
          .system-message .animation-wrapper .content__btn:focus:before {
            -webkit-animation: ripple-wave 0.5s ease-out;
                    animation: ripple-wave 0.5s ease-out; }
        .system-message .animation-wrapper .content__btn:hover {
          background-color: #5B8121;
          background-color: var(--green-3); }
        @media only screen and (max-width: 50em) {
          .system-message .animation-wrapper .content__btn {
            width: 18.75rem; } }
        @media only screen and (max-width: 37.5em) {
          .system-message .animation-wrapper .content__btn {
            font-size: 1rem;
            width: 12.5rem; } }
      .system-message .animation-wrapper .content__login-text {
        color: var(--edl-color-gray60); }
        .system-message .animation-wrapper .content__login-text a:hover {
          color: #188291;
          color: var(--green-7); }
        .system-message .animation-wrapper .content__login-text a {
          color: var(--edl-color-cerulean60); }
  .system-message .arrow {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 2.5rem;
    color: #949494;
    color: var(--dark-grey-7);
    cursor: pointer;
    z-index: 10;
    transition: color 0.2s;
    transition: color var(--fast);
    -webkit-user-select: none;
            user-select: none; }
    @media only screen and (max-width: 65.625em) {
      .system-message .arrow {
        height: 100%; } }
    .system-message .arrow:first-of-type {
      padding: 2.5625rem; }
      @media only screen and (max-width: 65.625em) {
        .system-message .arrow:first-of-type {
          padding-left: 0.375rem; } }
    .system-message .arrow:last-of-type {
      padding: 2.5625rem; }
      @media only screen and (max-width: 65.625em) {
        .system-message .arrow:last-of-type {
          padding-left: 0.375rem; } }
    .system-message .arrow:hover {
      color: #424242;
      color: var(--dark-grey-2); }

.progkat {
  grid-column: main-start / main-end;
  display: grid;
  position: relative;
  grid-template-columns: [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end];
  grid-template-columns: [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end];
  grid-template-rows: minmax(2.5rem, 1fr);
  border-radius: 0.375rem;
  box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.05);
  font-family: 'Roboto', sans-serif;
  grid-column-gap: 1.25rem;
  grid-column-gap: var(--gutter); }
  @media only screen and (max-width: 50em) {
    .progkat {
      border-radius: 0; } }
  .progkat > *:not(.progkat__cover) {
    z-index: 1;
    margin-bottom: 3.375rem; }
  .progkat__cover {
    position: absolute;
    grid-row: 1  / 3;
    grid-column: 1 / -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
    border-radius: 0.375rem; }
    .progkat__cover img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 0.5s;
      transition: all var(--slow);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden; }
  .progkat a {
    color: inherit; }
  .progkat__title {
    color: inherit;
    transition: 0.5s;
    transition: var(--slow);
    cursor: pointer; }
    .progkat__title.hover {
      color: #5B8121;
      color: var(--green-3);
      text-decoration: underline; }
  @media only screen and (max-width: 37.5em) {
    .progkat {
      padding: 0;
      grid-column: 1 / -1;
      border-radius: 0; } }
  .progkat .arrow {
    grid-row: 3/4;
    grid-column: -2 / -1;
    align-self: center;
    justify-self: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 3.125rem;
    height: 3.125rem;
    border: 0px;
    border-radius: 2.5rem;
    background-position: center;
    background-color: inherit;
    transition: background 0.2s, color 0.2s;
    transition: background var(--fast), color var(--fast);
    font-size: 1.875rem;
    color: #eaeaea;
    color: var(--light-grey-2);
    cursor: pointer;
    position: relative;
    overflow: hidden; }
    .progkat .arrow:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      display: block;
      position: absolute;
      height: 6.25rem;
      width: 6.25rem;
      -webkit-transform: scale(0.001, 0.001);
              transform: scale(0.001, 0.001); }
    .progkat .arrow:focus {
      outline: 0; }
      .progkat .arrow:focus:before {
        -webkit-animation: ripple-wave 0.8s ease-out;
                animation: ripple-wave 0.8s ease-out; }
    .progkat .arrow.hover {
      color: #426200;
      color: var(--green-4);
      background: #D7F4BD radial-gradient(circle, transparent 1%, #D7F4BD 1%) center/15000%;
      background: var(--light-green) radial-gradient(circle, transparent 1%, var(--light-green) 1%) center/15000%; }
    @media only screen and (max-width: 50em) {
      .progkat .arrow {
        display: none; } }
  .progkat .button, .progkat .section-selector-wrapper .section-title, .section-selector-wrapper .progkat .section-title {
    grid-column: 1 / -1;
    justify-self: start;
    margin-top: 1.125rem;
    margin-left: 1.25rem;
    transition: all 0.2s;
    transition: all var(--fast);
    -webkit-user-select: none;
            user-select: none;
    text-transform: uppercase; }
    .progkat .button span, .progkat .section-selector-wrapper .section-title span, .section-selector-wrapper .progkat .section-title span {
      transition: all 0.2s;
      transition: all var(--fast); }
    .progkat .button:hover, .progkat .section-selector-wrapper .section-title:hover, .section-selector-wrapper .progkat .section-title:hover {
      background-color: #5B8121;
      background-color: var(--green-3);
      color: white;
      border: transparent 2px solid; }
      .progkat .button:hover span, .progkat .section-selector-wrapper .section-title:hover span, .section-selector-wrapper .progkat .section-title:hover span {
        color: white; }
    @media only screen and (max-width: 50em) {
      .progkat .button, .progkat .section-selector-wrapper .section-title, .section-selector-wrapper .progkat .section-title {
        grid-column: 2 / -2;
        margin-left: 0; } }
    @media only screen and (max-width: 50em) {
      .progkat .button, .progkat .section-selector-wrapper .section-title, .section-selector-wrapper .progkat .section-title {
        margin-bottom: 1.5rem; } }
    @media only screen and (max-width: 37.5em) {
      .progkat .button, .progkat .section-selector-wrapper .section-title, .section-selector-wrapper .progkat .section-title {
        justify-self: center; } }
  .review:hover ~ .progkat__cover img {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  .progkat .review {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-radius: 0.375rem;
    grid-row-start: 2;
    grid-column: 5 / 11;
    background: white;
    min-height: 9.375rem;
    padding: 1.5rem;
    position: relative; }
    .progkat .review *:not(.arrow) {
      grid-column: 1 / 6; }
      @media only screen and (max-width: 50em) {
        .progkat .review *:not(.arrow) {
          grid-column: 1 / 7; } }
    @media only screen and (max-width: 65.625em) {
      .progkat .review {
        grid-column: 3 / -2; } }
    @media only screen and (max-width: 50em) {
      .progkat .review {
        grid-column: 2 / -2;
        margin-left: 0;
        margin-bottom: 1.5rem; } }
    @media only screen and (max-width: 37.5em) {
      .progkat .review {
        border-radius: 0;
        padding-bottom: 0.625rem;
        grid-column: 1 / -1; } }
    .progkat .review .from {
      color: var(--edl-color-gray60);
      font-size: 1rem;
      margin-bottom: 0.625rem; }
    .progkat .review__information {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      bottom: 1.5rem;
      margin-top: 1.5rem; }
      @media only screen and (max-width: 37.5em) {
        .progkat .review__information > * {
          margin-bottom: 0.75rem; } }
      .progkat .review__information .star {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width: 12.5rem;
        margin-right: 2.5rem;
        -webkit-align-items: center;
                align-items: center; }
        .progkat .review__information .star--empty {
          color: #E39D14;
          color: var(--orange-2);
          font-size: 1.4375rem;
          position: relative; }
        .progkat .review__information .star--half, .progkat .review__information .star--full {
          font-size: 1.4375rem;
          color: #FFD191;
          color: var(--orange-1); }
        .progkat .review__information .star .star__border {
          position: absolute;
          left: 0;
          top: 0;
          color: #E39D14;
          color: var(--orange-2);
          z-index: 20; }
        .progkat .review__information .star--half {
          position: relative; }
        .progkat .review__information .star--full {
          position: relative; }
        .progkat .review__information .star__rating {
          font-weight: bold;
          font-size: 1.25rem; }
      .progkat .review__information .people {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .progkat .review__information .people > *:not(:last-child) {
          margin-right: 0.625rem; }
        .progkat .review__information .people span {
          font-size: 1.5625rem;
          color: inherit; }
        .progkat .review__information .people__amount {
          font-style: italic; }

.spinner {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

/* Transparent Overlay */
.spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); }

/* :not(:required) hides these rules from IE9 and below */
.spinner:not(:required) {
  /* hide "spinner..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.spinner:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0; }

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.section-selector-2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin-top: calc(3rem - 1.5rem - 0.35625rem); }
  .section-selector-2::after {
    position: absolute;
    left: 0;
    content: '';
    display: block;
    width: 0.3125rem;
    height: 100%;
    background: linear-gradient(#f9f9f9 1.25rem, #D7F4BD, #D7F4BD, #f9f9f9);
    background: linear-gradient(var(--whitish-2) 1.25rem, var(--light-green), var(--light-green), var(--whitish-2)); }
  .section-selector-2 .title {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 1.5625rem;
    line-height: 2.25rem;
    color: var(--grey-3);
    padding: 0.9375em;
    padding-left: 1.125rem; }
  .section-selector-2 .list-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  @media only screen and (max-width: 65.625em) {
    .section-selector-2 .list-wrapper {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; } }
  .section-selector-2 .database-selector {
    background: none;
    border: none;
    outline: inherit;
    text-align: left;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.875rem;
    padding: 0.9375rem;
    padding-left: 2.1875rem;
    color: var(--blackish);
    cursor: pointer;
    background-size: 200% 100%;
    overflow: hidden;
    transition: background 0.2s linear, background-position 0.2s linear;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
            user-select: none; }
    .section-selector-2 .database-selector.active {
      color: #426200;
      color: var(--green-4);
      background-position: -100% 0%;
      border-radius: 0 0.375rem 0.375rem 0; }
  .section-selector-2 .item {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.875rem;
    padding: 0.9375rem;
    padding-left: 1.25rem;
    color: var(--blackish); }
  .section-selector-2 .list {
    list-style: none;
    counter-reset: counter;
    list-style-type: upper-roman; }
    .section-selector-2 .list li {
      counter-increment: counter;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      z-index: 1;
      -webkit-user-select: none;
              user-select: none; }
      .section-selector-2 .list li::after {
        display: block;
        content: '';
        background-color: #D7F4BD;
        background-color: var(--light-green);
        position: absolute;
        left: -100%;
        top: 0;
        transition: left 0.2s;
        transition: left var(--fast);
        height: 100%;
        width: 100%;
        border-radius: 0 1.6875rem 1.6875rem 0;
        z-index: -1; }
      .section-selector-2 .list li:hover {
        color: #426200;
        color: var(--green-4);
        text-decoration: underline; }
      .section-selector-2 .list li::before {
        content: counter(counter, upper-roman) ". ";
        color: var(--grey); }
      .section-selector-2 .list li.active {
        color: #426200;
        color: var(--green-4);
        text-decoration: none !important; }
        .section-selector-2 .list li.active::after {
          left: 0; }
        @media only screen and (max-width: 50em) {
          .section-selector-2 .list li.active {
            border-radius: 0; } }

.image-widget {
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter);
  grid-column: 1 / -1;
  grid-row-gap: 1.125rem;
  grid-auto-rows: 1fr;
  margin-top: 3rem; }
  .image-widget + .image-widget {
    margin-top: 0; }
    .image-widget + .image-widget .paper {
      top: 0; }
  .image-widget > div:not(.react-carousel) {
    height: 26.25rem; }
  .image-widget.image-single {
    margin-top: 0;
    height: 0;
    z-index: 100;
    position: relative;
    top: 3.375rem; }
    .image-widget.image-single + .text-widget {
      min-height: 30.75rem; }
    .image-widget.image-single .image-left-small > div {
      border-radius: 0.375rem !important; }
    @media only screen and (max-width: 65.625em) {
      .image-widget.image-single {
        height: auto;
        top: 0;
        padding-top: 3.375rem;
        z-index: unset; }
        .image-widget.image-single + .text-widget {
          min-height: unset; }
        .image-widget.image-single .image-left-small {
          grid-column: 5 / 11;
          margin-top: 0;
          width: 22.5rem;
          justify-self: center;
          margin: 0px; } }
    .image-widget.image-single .paper {
      display: none;
      height: calc(100% + 3.375rem) !important;
      top: -3.375rem; }
      @media only screen and (max-width: 65.625em) {
        .image-widget.image-single .paper {
          display: block; } }
  .image-widget > *:not(.paper) {
    position: relative;
    z-index: 100;
    grid-row: 1 / 2;
    margin-top: 1.125rem; }
    .image-widget > *:not(.paper)::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.2s;
      transition: box-shadow var(--fast);
      z-index: -1; }
  .image-widget .paper {
    position: absolute;
    grid-column: col-start 4 / col-end 11;
    grid-column: var(--col-5-11-grid);
    background-color: #ffffff;
    background-color: var(--whitish);
    padding: 3rem 6.25rem 0rem 6.25rem;
    padding: var(--col-5-11-padding);
    position: relative;
    width: 100%;
    grid-row: 1 / 2;
    height: calc(100% + 3rem) !important;
    top: -3rem;
    z-index: -1; }
    .image-widget .paper::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: -1;
      box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.2s;
      transition: box-shadow var(--fast); }
  .image-widget .border-rad-top-left::after,
  .image-widget .border-rad-top-left > div {
    border-top-left-radius: 0.375rem; }
    @media only screen and (max-width: 50em) {
      .image-widget .border-rad-top-left::after,
      .image-widget .border-rad-top-left > div {
        border-top-left-radius: 0rem; } }
  .image-widget .border-rad-top-right::after,
  .image-widget .border-rad-top-right > div {
    border-top-right-radius: 0.375rem; }
    @media only screen and (max-width: 50em) {
      .image-widget .border-rad-top-right::after,
      .image-widget .border-rad-top-right > div {
        border-top-right-radius: 0rem; } }
  .image-widget .border-rad-bottom-left::after,
  .image-widget .border-rad-bottom-left > div {
    border-bottom-left-radius: 0.375rem; }
    @media only screen and (max-width: 50em) {
      .image-widget .border-rad-bottom-left::after,
      .image-widget .border-rad-bottom-left > div {
        border-bottom-left-radius: 0rem; } }
  .image-widget .border-rad-bottom-right::after,
  .image-widget .border-rad-bottom-right > div {
    border-bottom-right-radius: 0.375rem; }
    @media only screen and (max-width: 50em) {
      .image-widget .border-rad-bottom-right::after,
      .image-widget .border-rad-bottom-right > div {
        border-bottom-right-radius: 0rem; } }
  .image-widget .image-left-medium {
    grid-column: 2 / 8; }
  .image-widget .image-right-medium {
    grid-column: 8 / 14; }
  .image-widget .image-left-small {
    grid-column: 2 / 6;
    margin-right: 1.25rem;
    position: relative; }
    @media only screen and (max-width: 37.5em) {
      .image-widget .image-left-small {
        grid-column: 1 / 6; } }
    .image-widget .image-left-small .caption {
      position: absolute;
      top: calc(100% + 0.8125rem);
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: #949494;
      color: var(--dark-grey-7);
      grid-column: 2 / 4;
      margin-right: 5rem; }
  .image-widget .image-center-small {
    grid-column: 6 / 10;
    width: calc(100% + 2.5rem);
    margin-left: -1.25rem; }
  .image-widget .image-center-large {
    grid-column: 6 / 12; }
    .image-widget .image-center-large img {
      border-radius: 0.375rem; }
  .image-widget .image-right-small {
    grid-column: 10 / 14;
    margin-left: 1.25rem; }
    @media only screen and (max-width: 37.5em) {
      .image-widget .image-right-small {
        grid-column: 10 / -1; } }
  .image-widget .image-right-large {
    grid-column: 6 / 14;
    width: calc(100% + 1.25rem);
    margin-left: -1.25rem; }
    @media only screen and (max-width: 37.5em) {
      .image-widget .image-right-large {
        grid-column: 6 / -1; } }
  .image-widget .image-left-large {
    grid-column: 2 / 10;
    width: calc(100% + 1.25rem); }

.article {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter);
  grid-row-gap: 0;
  z-index: 910;
  position: relative; }
  .article ~ div {
    z-index: 100 !important; }
  .article .author-box {
    padding-bottom: 3.5rem !important;
    padding: 3rem 6.25rem 0rem 6.25rem;
    padding: var(--col-5-11-padding);
    grid-column: col-start 4 / col-end 11;
    grid-column: var(--col-5-11-grid); }
  .article .article__tags {
    grid-column: col-start 4 / col-end 11;
    grid-column: var(--col-5-11-grid);
    padding: 3rem 6.25rem 0rem 6.25rem;
    padding: var(--col-5-11-padding);
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #ffffff;
    background-color: var(--whitish); }
    @media only screen and (max-width: 50em) {
      .article .article__tags {
        padding: 1.25rem; } }
  .article .react-carousel {
    margin-top: 0;
    grid-column: main-start / main-end; }
    @media only screen and (max-width: 50em) {
      .article .react-carousel {
        --rc-border-radius: 0;
        grid-column: 1 / -1; } }
    .article .react-carousel .thumbnail-wrapper {
      border: 1em solid var(--rc-slider-bg);
      height: 100%;
      width: 100%;
      overflow: hidden; }
  .article > *:not(:last-child) {
    position: relative; }
  .article > *:not(.article__social-media):not(.image-widget)::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.2s;
    transition: box-shadow var(--fast);
    z-index: -1; }
  .article > *:nth-last-child(2)::after, .article > *:nth-last-child(2) {
    border-radius: 0 0 0.375rem 0.375rem; }
    @media only screen and (max-width: 37.5em) {
      .article > *:nth-last-child(2)::after, .article > *:nth-last-child(2) {
        border-radius: 0; } }
  .article__cover {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    grid-row: 1 / 2;
    align-self: end;
    min-height: 21.875rem;
    height: 30vw;
    max-height: 37.5rem;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.2s;
    transition: box-shadow var(--fast); }
    .article__cover img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%; }
  .article__title {
    grid-column: col-start 4 / col-end 11;
    background-color: #ffffff;
    background-color: var(--whitish);
    padding: 1.25rem 1.875rem 0;
    grid-row: 1 / 2;
    align-self: end;
    border-radius: 0.375rem 0.375rem 0 0;
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-animation: fadeIn 0.5s;
            animation: fadeIn 0.5s;
    -webkit-animation: fadeIn var(--slow);
            animation: fadeIn var(--slow); }
    @media only screen and (max-width: 65.625em) {
      .article__title {
        grid-column: col-start 2 / col-end 11; } }
    @media only screen and (max-width: 50em) {
      .article__title {
        grid-column: main-start / main-end;
        padding: 0.625rem 1.25rem 0 1.25rem; } }
    @media only screen and (max-width: 37.5em) {
      .article__title {
        border-radius: 0; } }
    .article__title .info-lane {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      grid-template-columns: -webkit-max-content -webkit-max-content 1fr;
      grid-template-columns: max-content max-content 1fr;
      grid-row-gap: 0.3125rem;
      -webkit-align-items: center;
              align-items: center; }
      @media only screen and (max-width: 50em) {
        .article__title .info-lane {
          grid-template-columns: 1fr; }
          .article__title .info-lane .author {
            justify-self: start;
            cursor: pointer; } }
      .article__title .info-lane a {
        color: var(--edl-color-cerulean50); }
    .article__title .eta {
      font-family: 'Open Sans Bold'; }
    .article__title .author {
      cursor: pointer;
      color: #12A3B4;
      color: var(--blue);
      font-style: italic;
      justify-self: end;
      grid-column: -1; }
      .article__title .author:hover {
        text-decoration: underline;
        color: #188291;
        color: var(--green-7); }
      @media only screen and (max-width: 50em) {
        .article__title .author {
          grid-column: auto;
          justify-self: start; } }
    .article__title .title {
      margin-top: 0.75rem;
      color: #424242 !important;
      color: var(--dark-grey-2) !important; }
  @media only screen and (max-width: 37.5em) {
    .article > * {
      grid-column: 1 / -1 !important; } }

.article__lead {
  grid-column: col-start 4 / col-end 11;
  grid-column: var(--col-4-11-grid);
  padding: 1.25rem 1.875rem;
  padding: var(--col-4-11-padding);
  background-color: #ffffff;
  background-color: var(--whitish);
  -webkit-animation: fadeIn 0.5s;
          animation: fadeIn 0.5s;
  -webkit-animation: fadeIn var(--slow);
          animation: fadeIn var(--slow); }

.author-page {
  padding-top: 2.5rem;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter);
  margin-bottom: 2.25rem; }
  .author-page .h4 {
    margin-bottom: 1.125rem; }
  .author-page .main-content {
    grid-column: main-start / col-end 8;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    @media only screen and (max-width: 50em) {
      .author-page .main-content {
        grid-column: main-start / main-end; } }
    @media only screen and (max-width: 37.5em) {
      .author-page .main-content {
        grid-column: 1 / -1; } }
    .author-page .main-content .author-box {
      margin-bottom: 2.25rem; }
      .author-page .main-content .author-box .inner {
        border-radius: 6px; }
        @media only screen and (max-width: 37.5em) {
          .author-page .main-content .author-box .inner {
            border-radius: 0; } }
        .author-page .main-content .author-box .inner__image {
          cursor: auto; }
    .author-page .main-content > * {
      margin-bottom: 1.125rem; }
  .author-page aside {
    grid-column: col-start 9 / main-end; }
    .author-page aside > *:not(.section-selector) {
      margin-bottom: 1.125rem; }
    @media only screen and (max-width: 50em) {
      .author-page aside {
        display: none; } }

.rubrics-page {
  padding-top: 2.5rem;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, calc((1180px - 11 * 1.25rem) / 12)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end] minmax(0, 1fr) [full-end];
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 1.25rem;
  grid-gap: var(--gutter);
  -webkit-animation: fadeIn 0.5s;
          animation: fadeIn 0.5s;
  -webkit-animation: fadeIn var(--slow);
          animation: fadeIn var(--slow); }
  .rubrics-page .h4 {
    grid-row: 1 / 2;
    grid-column: main-start / main-end;
    color: #777;
    color: var(--dark-grey-6);
    margin-bottom: 1.125rem; }
    @media only screen and (max-width: 50em) {
      .rubrics-page .h4 {
        margin-left: 1.25rem; } }
  .rubrics-page .main-content {
    grid-column: main-start / col-end 8;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    @media only screen and (max-width: 50em) {
      .rubrics-page .main-content {
        grid-column: main-start / main-end; } }
    @media only screen and (max-width: 37.5em) {
      .rubrics-page .main-content {
        grid-column: 1 / -1; } }
    .rubrics-page .main-content > * {
      margin-bottom: 1.125rem; }
      .rubrics-page .main-content > *:nth-last-child(2) {
        margin-bottom: 3.375rem; }
  .rubrics-page aside {
    grid-column: col-start 9 / main-end; }
    .rubrics-page aside > * {
      margin-bottom: 1.25rem;
      margin-bottom: var(--gutter); }
    @media only screen and (max-width: 50em) {
      .rubrics-page aside {
        grid-row: 1;
        grid-column: main-start / main-end; } }

.error404 {
  grid-column: main-start / main-end;
  grid-column-gap: 1.25rem;
  height: 6.25rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1.25rem;
  margin-top: 9.375rem;
  margin-bottom: 6.875rem; }
  .error404 .error-box {
    grid-column: 2 / 6;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .error404 .error-box .error {
      color: #EAEAEA;
      font-size: 11.25rem !important;
      font-family: Roboto, sans-serif;
      font-weight: bold;
      line-height: 11.25rem;
      margin-top: 1.875rem; }
    .error404 .error-box .text {
      font-family: Open Sans, sans-serif;
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 1.875rem;
      padding-top: 0.44375rem;
      margin-bottom: -0.44375rem;
      color: var(--grey-2);
      margin-bottom: 3rem; }
  .error404 .image {
    grid-column: 7 / 13;
    width: 100%; }

.not-found {
  grid-column: main-start / main-end;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1.25rem;
  padding-top: 3.375rem;
  margin-bottom: 11.25rem; }
  @media only screen and (max-width: 1150px) {
    .not-found {
      margin-bottom: 0; } }
  @media only screen and (max-width: 600px) {
    .not-found {
      grid-column: 1 / -1; } }
  .not-found .image {
    grid-column: 7 / 12;
    width: 100%;
    grid-row: 1;
    z-index: 1; }
    @media only screen and (max-width: 1150px) {
      .not-found .image {
        grid-column: 4 / 10; } }
    @media only screen and (max-width: 800px) {
      .not-found .image {
        grid-column: 1 / -1; } }
  .not-found .main-box {
    margin-top: 8.5%;
    grid-column: 1 / 7;
    background-color: #def1fb;
    background-color: var(--blue-3);
    height: 80.8%;
    width: calc(100% + 1.375rem);
    grid-row: 1;
    z-index: 0;
    padding: 6.25rem;
    padding-bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    @media only screen and (max-width: 1150px) {
      .not-found .main-box {
        grid-column: 4 / 10;
        grid-row: 2 / 3;
        padding: 1.875rem;
        width: 100%;
        height: 31.25rem;
        position: relative;
        top: -9%;
        padding-top: 6.875rem; } }
    @media only screen and (max-width: 800px) {
      .not-found .main-box {
        position: relative;
        top: -10.5%;
        grid-column: 1 / -1; } }
    .not-found .main-box .text {
      text-align: center;
      font-family: Roboto, sans-serif;
      font-weight: bold;
      font-size: 2.4375rem;
      line-height: 3rem;
      color: var(--grey-6);
      max-width: 23.75rem;
      margin-bottom: 3.75rem; }

.RubricSelectorWrapper {
    grid-column: 1 / -1;
    z-index: 10;
    display: grid;
    margin: 0;
    box-sizing: inherit;
    grid-column: main-start / main-end;
    padding-top: 3rem;
}

.RubricSelectorWrapper__content {
    padding-top: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.RubricSelectorWrapper__content__left {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
}

.RubricSelectorWrapper__content__right {
    padding-left: 1rem;
}

.section-selector-wrapper__tovabb {
    cursor: pointer;
    color: var(--blue);
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    line-height: 1.875rem;
    grid-column: 10 / 14;
    grid-row: 4 / 5;
    align-self: end;
}

.section-selector-wrapper__tovabb:hover {
    text-decoration: underline;
}

.taglist {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 5px 0 5px 10px;
}

.taglist:focus-within {
    box-shadow: var(--edl-btn-outline);
    outline: 2px solid var(--edl-color-white);
    border-radius: 1.875rem;
}

.taglist a {
    margin: 5px 1rem 5px 0;
    text-decoration: none;
}

.taglist a:focus {
    box-shadow: var(--edl-outline);
}

.section-item:focus {
    border:2px solid #ABCBFB!important;
    outline: 2px!important;
    border-radius: 0 1.6875rem 1.6875rem 0
}
.CategoryTitle {
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;

}

.CategoryTitle a {
    padding: 0 2.5rem;
    --edl-btn-outline-background-color: var(--edl-color-white);
}

.CategoryTitle a h2 {
    font-size: 1rem;
}

.HighLightedArticle {
    width: 100%;
    grid-column: 1/-1;
}


.section-title {
    display: inline-block;
    position: relative;
    left: calc(100% / 5);
    top: 4rem;
    z-index: 100;
}

.HighLightedArticle__cover {
    height: 30vw;
    min-height: 300px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
}

.HighLightedArticle__content {
    border-radius: .375rem;
    background-color: white;
    padding: 1.25rem;
    max-width: 780px;
    margin: auto;
    top: -7rem;
    position: relative;
    box-shadow: 0 12px 15px rgba(0, 0, 0, .05);
}

.HighLightedArticle__content:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}

.HighLightedArticle__content__header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.HighLightedArticle__content__header span {
    color: var(--edl-color-gray60);
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
}

.HighLightedArticle__content__header a {
    font-style: italic;
    font-weight: 400;
    text-transform: capitalize;
}

.HighLightedArticle__content__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.HighLightedArticle__content__content__left {
    width: 100%;
    padding-top: 1rem;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
}

.HighLightedArticle__content__content__left a:not(.edl-btn) {
    font-weight: 700;
    font-size: 1.9375rem;
    line-height: 2.25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--dark-grey-4);
}
.HighLightedArticle__content__content__left a h3 {
    font-size: 1.9375rem;
}

.HighLightedArticle__content__content__left-lead {
    color: var(--edl-color-gray60);
}

.HighLightedArticle__content__content__left__tags {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.HighLightedArticle__content__content__left__tags a {
    padding: .1rem 1rem;
    color: var(--dark-grey-4);
    background-color: var(--light-grey-1);
    border-radius: 1.25rem;
    -webkit-align-items: center;
            align-items: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    margin-right: 1rem;
    margin-top: 0.5rem;
}

.HighLightedArticle__content__content__left__tags a:hover {
    background-color: var(--green-3);
    color: white;
    text-decoration: none;
}

.HighLightedArticle__content__content__right {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
}

.HighLightedArticle__content__content__right a {
    color: var(--light-grey-2);
    margin-right: 2rem;
    padding: 0.8rem 1rem;
}

.HighLightedArticle__content__content__right a:hover {
    color: var(--green-3);
    background-color: var(--light-green);
    border-radius: 2rem;
    text-decoration: none !important;
}

.RegistrationCard__button {
    text-decoration: none!important;
    text-transform: uppercase;
    background-color: var(--edl-color-lime50);
}

.registration-card {
    -webkit-justify-content: center !important;
            justify-content: center !important;
}

.pagination {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: var(--gutter);

    color: var(--light-grey-3);
    list-style: none;
}

.pagination-selected {
    background-color: var(--light-green)!important;
    color: black!important;
}

.pagination li button {
    border: none;
    background: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 36px;
    width: 36px;

    color: var(--light-grey-3);
    font-weight: 900;
    font-size: 1.25rem;
}
.pagination li button:disabled {
    color: var(--light-grey-1);
}

.pagination li button:hover:not([disabled]) {
    cursor: pointer;
    color: var(--green-3);
    background-color: var(--light-green);
    border-radius: 50%;
}

