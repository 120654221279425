.h1 {
    @extend .B-Rb31;
}

.h2 {
    @extend .B-Rb25;
}

.h3 {
    @extend .h1;
    font-size: 1.25rem;
    line-height: unset;
}

.h4 {
    text-transform: uppercase;

    @extend .B-R16;
}

.f-h1 {
    @include get-font(Rb31);
    @include margin-top(Rb31, $s78);
    color: var(--blackish);
}

.f-h2 {
    @include get-font(Rb25);
    color: var(--blackish);
}

.f-h3 {
    @include get-font(Rb20);
    color: var(--blackish);
}

.p {
    @include get-font(OS16);
    @include margin-top(OS16, 3.375rem, $shutup);
}

.bold {
    font-weight: bold;
    color: var(--blackish);
}

a {
    color: var(--blue);
    cursor: pointer;
}

.label {
    color: var(--edl-color-gray60);
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
}

strong {
    font-family: 'Open Sans', sans-serif;
}

@include media(tab-port) {
    .h1 {
        font-size: 1.375rem;
        line-height: unset;
    }

    .h2 {
        font-size: 1.125rem;
        line-height: unset;
    }

    .h3 {
        font-size: 1.0625rem;
        line-height: unset;
    }
}
