.article__social-media {
    grid-column: col-start 3/col-end 3;
    grid-row: 2 / 4;
    align-self: start;
    display: flex;
    flex-direction: column;
    margin-top: 3.625rem;
    padding: $s12 var(--col-5-11-padding);
    animation: fadeIn var(--slow);
    position: absolute;

    button {
        background-color: transparent;
    }

    @include media(tab-land) {
        margin-top: 0;
        flex-direction: row;
        position: unset;
    }

    .clip-board-success {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        height: 3rem;
        bottom: 10vh;
        border-radius: $s24;
        padding: 0rem 1.25rem;
        font-size: 1rem;
        background-color: rgba(0,0,0,.7);
        color: white;
        pointer-events: none;
        user-select: none;
        z-index: 1100;

        animation: appear-then-disappear 5s forwards;
    }
    
    input {
        position: absolute;
        bottom: 100%;
        opacity: 0;
        z-index: -1000;
        pointer-events: none;
        right: 100%;
    }

    & > * {
        display: flex;
        padding: $s12 0.875rem;
        font-size: 1.875rem;
        justify-content: center;
        align-items: center;
        color: var(--light-grey-4);
        cursor: pointer;

        &:hover {
            color: var(--dark-grey-4);
        }
    }

    @include media(tab-land) {
        grid-row: unset;
        grid-column: 3 / -2;
    }

    @include media(tab-port) {
        grid-column: var(--col-5-11-grid);
    }
}
