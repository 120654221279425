.welcome {
    grid-column: main-start / main-end;
    background-color: var(--light-grey-0);
    @include shadow;
    padding: 1.875em 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: $s36;
    margin-bottom: $s54;
    z-index: 1;

    .title {
        @extend .B-Rb31;
    }

    .title,
    .btn,
    .text {
        margin-bottom: var(--gutter);
        width: 100%;
        max-width: 48.75rem;
        flex-grow: 0;
    }

    .text {
        font-size: 1.25rem;
        line-height: 1.875rem;
    }

    .btn {
        max-width: 23.75rem;
    }

    .or-login {
        font-size: 1rem;
        color: var(--light-grey-3);
    }
}
