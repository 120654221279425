.CategoryTitle {
    display: flex;
    justify-items: center;
    align-items: center;

}

.CategoryTitle a {
    padding: 0 2.5rem;
    --edl-btn-outline-background-color: var(--edl-color-white);
}

.CategoryTitle a h2 {
    font-size: 1rem;
}
