:root {
    // COLORS
    --whitish: #ffffff;
    --whitish-2: #f9f9f9;
    --cream: #f5f5f5;

    --dark-grey-1: #212121;
    --dark-grey-2: #424242;
    --dark-grey-3: #545454;
    --dark-grey-4: #595959;
    --dark-grey-5: #707070;
    --dark-grey-6: #777;
    --dark-grey-7: #949494;
    --dark-grey-8: #464646;

    --light-grey-0: #f5f5f5;
    --light-grey-1: #eeeeee;
    --light-grey-2: #eaeaea;
    --light-grey-3: #bdbdbd;
    --light-grey-4: #D8D8D8;
    --light-grey-5: #F3F3F3;

    --light-green: #D7F4BD;
    --green: #41c058;
    --green-2:#73A22C;
    --green-3:#5B8121;
    --green-4: #426200;
    --green-5: #81B532;
    --green-6: #95D13C;
    --green-7: #188291;
    --blue: #12A3B4;
    --blue-2: #00B6CB;
    --blue-3: #def1fb;
    --blue-4: #D1F0F7;
    --yellow: #ffc000;
    --yellow-faded: #ffbf0062;
    --orange-1: #FFD191;
    --orange-2: #E39D14;

    --backdrop: rgba(0, 0, 0, 0.5);

    // SPACING
    --gutter: 1.25rem;
    --col-max-width: calc((73.75rem - 11 * var(--gutter)) / 12);
    --col2-max-width: calc((73.75rem - 1 * var(--gutter)) / 2);
    --col3-max-width: calc((73.75rem - 2 * var(--gutter)) / 3);
    --col4-max-width: calc((73.75rem - 3 * var(--gutter)) / 4);

    // TIMINGS
    --fast: 0.2s;
    --slow: 0.5s;
    --very-slow: 0.7s;

    // SCALE
    --small-scale: 1.04;
    --medium-scale: 1.08;

    // TRANSFORMS
    --small-adjust: calc( ( var(--small-scale) - 1 ) / -2 * 100% );

    // LAYOUT
    --col-4-11-grid: col-start 4 / col-end 11;
    --col-4-11-padding: 1.25rem 1.875rem;
    --col-5-11-grid: col-start 4 / col-end 11;
    --col-5-11-padding: 3rem 6.25rem 0rem 6.25rem;

    --margin-of-top: 3.85871em;

	// GRID
	--col-max-width: calc((1180px - 11 * var(--gutter)) / 12);
}

@media only screen and (max-width: 65.625em) {
    :root {
        --col-4-11-grid: col-start 2 / col-end 11;
        --col-4-11-padding: 1.25rem 2.5rem;
        --col-5-11-grid: col-start 2 / col-end 11;
        --col-5-11-padding: 1.25rem 2.5rem 1.25rem 10%;
    }
}

@media only screen and (max-width: 50em) {
    :root {
        --col-4-11-grid: main-start / main-end;
        --col-4-11-padding: 0.625rem 1.25rem;
        --col-5-11-grid: main-start / main-end;
        --col-5-11-padding: 2.5rem 1.25rem;
    }
}

@media only screen and (max-width: 37.5em) {
    :root {
        --gutter: 0.9375rem;

        --col-5-11-grid: 1 / -1;
    }
}
