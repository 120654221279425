.error404 {
    grid-column: main-start / main-end;
    grid-column-gap: 1.25rem;
    height: 6.25rem;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1.25rem;
    margin-top: 9.375rem;
    margin-bottom: 6.875rem;

    .error-box {
        grid-column: 2 / 6;

        display: flex;
        align-items: center;
        flex-direction: column;

        .error {
            color: #EAEAEA;
            font-size: 11.25rem !important;
            font-family: Roboto, sans-serif;
            font-weight: bold;
            line-height: 11.25rem;
            margin-top: 1.875rem;
        }

        .text {
            @include get-font(OS20);
            color: var(--grey-2);
            margin-bottom: 3rem;
        }
    }

    .image {
        grid-column: 7 / 13;
        width: 100%;
    }
}