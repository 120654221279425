.author-page {
    padding-top: 2.5rem;
    grid-column: 1 / -1;
    @include full-grid;
    margin-bottom: $s36;

    .h4 {
        margin-bottom: $s18;
    }

    .main-content {
        grid-column: main-start / col-end 8;
        @include media(tab-port) {
            grid-column: main-start / main-end;
        }

        @include media(phone) {
            grid-column: 1 / -1;
        }

        .author-box {
            margin-bottom: $s36;
            .inner {
                @include border-radius;
                &__image {
                    cursor: auto;
                }
            }
        }

        display: flex;
        flex-direction: column;

        & > * {
            margin-bottom: $s18;
        }
    }

    aside {
        grid-column: col-start 9 / main-end;
        
        & > *:not(.section-selector) {
            margin-bottom: $s18;
        }

        @include media(tab-port) {
            display: none;
        }
    }
}
