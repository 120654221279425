@mixin media($mediaType) {
    @if $mediaType == phone {
        @media only screen and (max-width: 37.5em) {
            // 600px / 16 = 37.5em
            @content;
        }
    }
    @if $mediaType == tab-port {
        @media only screen and (max-width: 50em) {
            // 800px / 16 = 50em
            @content;
        }
    }
    @if $mediaType == tab-land {
        @media only screen and (max-width: 65.625em) {
            // 1050px / 16 = 65.625em
            @content;
        }
    }
    @if $mediaType == small-desk {
        @media only screen and (max-width: 75em) {
            // 1200px / 16 = 75em including 1180ox
            @content;
        }
    }
}

@mixin gridColumns() {
    grid-template-columns:
        [full-start] minmax(0, 1fr) [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end])
        [main-end] minmax(0, 1fr) [full-end];
}

@mixin shadow() {
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
    transition: box-shadow var(--fast);
}

@mixin shadow-hover() {
    &:hover {
        box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
    }
}

@mixin border-radius() {
    border-radius: 6px;

    @include media(phone) {
        border-radius: 0;
    }
}

@mixin full-grid() {
    display: grid;
    grid-template-columns:
        [full-start] minmax(0, 1fr) [main-start]
        repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end])
        [main-end] minmax(0, 1fr) [full-end];
    grid-template-rows: min-content;
    grid-gap: var(--gutter);
}

@mixin side-padding() {
    padding: 0 1.25rem;
}

@mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin ripple($width, $background: rgba(255, 255, 255, 0.4), $animation: 0.5s) {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        background-color: $background;
        border-radius: 50%;
        display: block;
        position: absolute;
        height: $width;
        width: $width;
        transform: scale(0.001, 0.001);
    }

    &:focus {
        outline: 0;
        &:before {
            animation: ripple-wave $animation ease-out;
        }
    }
}
