.article-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--gutter);
  background-color: var(--whitish);
  overflow: hidden;
  padding-bottom: 2rem;
  @include shadow();
  @include shadow-hover();
  @include border-radius();

  & > *:not(.article-card__image):not(.taglist) {
    padding: 0 1.25rem;
  }

  &__image {
    width: 100%;
    height: 13.125rem;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    position: relative;
    margin-bottom: 1.3125rem;
    cursor: pointer;
    transition: all var(--slow);
    user-select: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all var(--very-slow);
    }
  }

  &__image {
    &.hover, &:hover {
      img {
        transform: scale(var(--small-scale)) translateY(var(--small-adjust));
        backface-visibility: hidden;
      }
    }

    &:hover ~ .article-card__title {
      color: var(--green-3);
      text-decoration: underline;
    }
  }

  &__label {
    @extend .label;
    margin-bottom: 0.5625rem;
    line-height: 1rem;
    display: flex;
    align-items: center;

    @include media(phone) {
      @include side-padding;
    }
  }

  &__title {
    @extend .h2;
    color: var(--dark-grey-4);
    margin-bottom: $s12;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: var(--fast);

    overflow: hidden;

    @supports (-webkit-line-clamp: 2) and (display: -webkit-box) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }

    &:focus {
      box-shadow: var(--edl-btn-outline);
      outline: 2px solid var(--edl-color-white);
    }
    &.hover, &:hover, &:focus {
      color: var(--green-3);
      text-decoration: underline;
    }

    h3 {
      font-size: 1.5625rem;
    }

    @include media(phone) {
      @include side-padding;
      grid-column: 1 / -1;
    }
  }

  .article-card__image {
    &.hover {
      img {
        transform: scale(var(--small-scale)) translateY(var(--small-adjust));
        backface-visibility: hidden;
      }
    }
  }

  &__text {
    margin-bottom: $s18;
    overflow: hidden;
    @supports (-webkit-line-clamp: 3) and (display: -webkit-box) {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }

    @include media(phone) {
      @include side-padding;
    }
  }

  &__tags {
    margin-bottom: $s12;

    .tag {
      margin-bottom: $s12;
    }

    @include media(phone) {
      @include side-padding;
    }
  }
}

.article-card.horizontal {
  grid-template-columns: 17.5rem 1fr;
  grid-template-rows: min-content min-content 1fr;
  height: 13.125rem;
  animation: fadeIn var(--very-slow);

  & > * {
    padding-left: 0;
  }

  .article-card__image {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    margin-bottom: 1.3125rem;
  }

  .article-card__label {
    padding-top: $s18;

    @include media(tab-land) {
      padding-bottom: 0 !important;
      grid-row: 2;
      margin-top: 0 !important;
    }
  }

  .article-card__text {
    margin-bottom: 0;
    align-self: start;
    padding-bottom: 0 !important;
    overflow: hidden;
    @supports (-webkit-line-clamp: 3) and (display: -webkit-box) {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }
    @include media(tab-land) {
      grid-row: 4;
    }
  }

  .article-card__title {
    color: var(--dark-grey-4);

    &.hover {
      color: var(--green-3);
    }

    @include media(tab-land) {
      grid-row: 3 !important;
      padding-bottom: 0 !important;
    }
  }

  .article-card__tags {
    display: none;
  }

  .article-card__image {
    &.hover {
      img {
        transform: scale(var(--small-scale)) translateY(-0%) translateX(var(--small-adjust));

        @include media(tab-land) {
          transform: scale(var(--small-scale)) translateY(var(--small-adjust)) translateX(0%);
        }
      }
    }
  }

  .article-card__title {
    grid-row: 2;

    @include media(tab-port) {
      grid-row: 3;
    }
  }

  .article-card__image {
    &.hover img {
      transform: scale(var(--small-scale)) translateY(-0%) translateX(var(--small-adjust));

      @include media(tab-land) {
        transform: scale(var(--small-scale)) translateY(var(--small-adjust)) translateX(0%);
      }
    }
  }

  @include media(tab-land) {
    grid-template-columns: unset;
    grid-template-rows: unset;
    height: unset;
    padding-bottom: 1.125rem;

    & > *:not(.article-card__image) {
      padding: 0 1.25rem $s18 1.25rem;
    }

    .article-card__image {
      grid-column: unset;
      grid-row: unset;
    }

    .article-card__label {
      margin-top: $s12;
    }

    .article-card__tags {
      display: none;
    }
  }
}
