.HighLightedArticle {
    width: 100%;
    grid-column: 1/-1;
}


.section-title {
    display: inline-block;
    position: relative;
    left: calc(100% / 5);
    top: 4rem;
    z-index: 100;
}

.HighLightedArticle__cover {
    height: 30vw;
    min-height: 300px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
}

.HighLightedArticle__content {
    border-radius: .375rem;
    background-color: white;
    padding: 1.25rem;
    max-width: 780px;
    margin: auto;
    top: -7rem;
    position: relative;
    box-shadow: 0 12px 15px rgba(0, 0, 0, .05);
}

.HighLightedArticle__content:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}

.HighLightedArticle__content__header {
    display: flex;
    justify-content: space-between;
}

.HighLightedArticle__content__header span {
    color: var(--edl-color-gray60);
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
}

.HighLightedArticle__content__header a {
    font-style: italic;
    font-weight: 400;
    text-transform: capitalize;
}

.HighLightedArticle__content__content {
    display: flex;
    flex-direction: row;
}

.HighLightedArticle__content__content__left {
    width: 100%;
    padding-top: 1rem;
    display: grid;
    gap: 1rem;
}

.HighLightedArticle__content__content__left a:not(.edl-btn) {
    font-weight: 700;
    font-size: 1.9375rem;
    line-height: 2.25rem;
    width: fit-content;
    color: var(--dark-grey-4);
}
.HighLightedArticle__content__content__left a h3 {
    font-size: 1.9375rem;
}

.HighLightedArticle__content__content__left-lead {
    color: var(--edl-color-gray60);
}

.HighLightedArticle__content__content__left__tags {
    display: flex;
    flex-wrap: wrap;
}

.HighLightedArticle__content__content__left__tags a {
    padding: .1rem 1rem;
    color: var(--dark-grey-4);
    background-color: var(--light-grey-1);
    border-radius: 1.25rem;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    margin-right: 1rem;
    margin-top: 0.5rem;
}

.HighLightedArticle__content__content__left__tags a:hover {
    background-color: var(--green-3);
    color: white;
    text-decoration: none;
}

.HighLightedArticle__content__content__right {
    text-align: center;
    display: flex;
    justify-items: center;
    align-items: center;
}

.HighLightedArticle__content__content__right a {
    color: var(--light-grey-2);
    margin-right: 2rem;
    padding: 0.8rem 1rem;
}

.HighLightedArticle__content__content__right a:hover {
    color: var(--green-3);
    background-color: var(--light-green);
    border-radius: 2rem;
    text-decoration: none !important;
}
