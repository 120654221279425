@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-6.25rem);
    }

    80% {
        transform: translateX(0.625rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(6.25rem);
    }

    80% {
        transform: translateX(-0.625rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(1.875rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInTop {
    0% {
        opacity: 0;
        transform: translateY(1.875rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-37.5rem) scale(0.2);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(37.5rem) scale(0.2);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-37.5rem) scale(0.2);
    }
}

@keyframes slideOutRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(37.5rem) scale(0.2);
    }
}

@keyframes ripple-wave {
    50% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
    99% {
      transform: scale(0.001, 0.001);
      opacity: 0;
    }
    100% {
      transform: scale(0.001, 0.001);
      opacity: 1;
    }
}

@keyframes appear-then-disappear {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}