.not-found {
    grid-column: main-start / main-end;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1.25rem;
    padding-top: $s54;
    margin-bottom: 11.25rem;

    @media only screen and (max-width: 1150px) {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 600px) {
        grid-column: 1 / -1;
    }

    .image {
        grid-column: 7 / 12;
        width: 100%;
        grid-row: 1;
        z-index: 1;

        @media only screen and (max-width: 1150px) {
            grid-column: 4 / 10;
        }

        @media only screen and (max-width: 800px) {
            grid-column: 1 / -1;
        }
    }

    .main-box {
        margin-top:8.5%;
        grid-column: 1 / 7;
        background-color: var(--blue-3);
        height: 80.8%;
        width: calc(100% + 1.375rem);
        grid-row: 1;
        z-index: 0;
        padding: 6.25rem;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (max-width: 1150px) {
            grid-column: 4 / 10;
            grid-row: 2 / 3;
            padding: 1.875rem;
            width: 100%;
            height: 31.25rem;
            position: relative;
            top: -9%;
            padding-top: 6.875rem;
        }

        @media only screen and (max-width: 800px) {
            position: relative;
            top: -10.5%;
            grid-column: 1 / -1;
        }

        .text {
            text-align: center;
            @include get-regular-font(Rb39);
            color: var(--grey-6);
            max-width: 23.75rem;
            margin-bottom: 3.75rem;
        }
    }
}