*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  background-color: var(--body-bg);
}

.main {
  @extend .B-OS16;
  color: var(--dark-grey-4);
  background-color: var(--whitish-2);
  width: 100%;
  @include full-grid;
  grid-row-gap: 0;

  img {
    user-select: none;
  }
}

.container-3 {
  grid-column: main-start / main-end;
  @include full-grid;
  grid-template-columns: repeat(auto-fit, minmax(17.5rem, 1fr));

  @include media(phone) {
    grid-column: 1 / -1;
  }
}

#apertus-edl-footer {
  margin-top: 7.125rem;
}

.img-container {
  overflow: hidden;
  height: 22vw;
  min-height: 12.5rem;
  max-height: 26.25rem;

  img {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }
}

.visibility__hidden {
  height: 0;
  visibility: hidden
}

.SocialMediaShareButton {
  border-radius: 50%;
  &:focus {
    box-shadow: var(--edl-outline);
  }
}
