@mixin left-border {
    content: '';
    position: absolute;
    top: 1.25rem;
    bottom: 1.25rem;
    left: 0;
    width: 5px;
    transition: all var(--slow);
}

@mixin bottom-border {
    content: '';
    position: absolute;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    height: 0.3125rem;
}

.header-wrapper {
    grid-column: 1 / -1;
    background-color: white;
    @include full-grid;

    .header {
        grid-column: main-start / main-end;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .product-related,
    .user-related {
        display: flex;
    }

    .logo {
        width: 15.625rem;
        height: 5rem;
        overflow: hidden;
        padding: 0 1.25rem;

        img {
            width: 100%;
            opacity: 0.8;
            transform: translateY(calc(-50% + 2.5rem)) rotate(-15deg);
        }
    }

    .menu-item {
        @extend .h3;
        padding: 1.875rem;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 300;
        color: var(--dark-grey-4);
        position: relative;
        display: flex;
        align-items: center;

        &.login::before {
            @include left-border;
            background-color: var(--green);
        }

        &.register::before {
            @include left-border;
            background-color: var(--blue);
        }

        &.selected::before {
            @include bottom-border;
            background-color: var(--green);
        }

        &.bold {
            font-weight: bold;
        }
    }
}
