.section-articles {
    grid-column: 1 / -1;
    z-index: 10;

    @include full-grid;

    .card-holder {
        z-index: 1;

        @include media(phone) {
            grid-column: 1 / -1;
        }
    }
}
