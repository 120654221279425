.taglist {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 5px 0 5px 10px;
}

.taglist:focus-within {
    box-shadow: var(--edl-btn-outline);
    outline: 2px solid var(--edl-color-white);
    border-radius: 1.875rem;
}

.taglist a {
    margin: 5px 1rem 5px 0;
    text-decoration: none;
}

.taglist a:focus {
    box-shadow: var(--edl-outline);
}
