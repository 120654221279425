.section-selector-wrapper {
    @include full-grid;
    grid-row-gap: 0;
    grid-column: 1 / -1;
    grid-template-rows: repeat(4, min-content);

    @include media(tab-port) {
        & > *:not(.learn-more) {
            display: none;
        }
    }

    .section-title {
        margin-top: $s114;
        margin-bottom: $s18;
        @extend .button;
        @extend .button--green;
        justify-self: start;
        align-self: center;
        text-decoration: unset;
    }
    .card-holder {
        grid-column: main-start / col-end 8;
        margin-bottom: $s18;

        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }

        @include media(phone) {
            display: none;
        }
    }

    aside {
        grid-column: col-start 9/main-end;
        grid-row: 2 / -1;
    }

    &__highlight:hover {
        text-decoration: underline;
    }

    .learn-more {
        cursor: pointer;
        background: none;
        border: none;
        outline: inherit;
        grid-column: main-start / col-end 8;
        grid-column: 10 / 14;
        grid-row: 4 / 5;
        padding-bottom: 0.75rem;
        color: var(--blue);
        text-align: right;
        align-self: end;
        text-transform: uppercase;
        @extend .B-Rb20;
        z-index: 5;
        user-select: none;
        
        @include media(tab-port) {
            font-size: 1rem;
            grid-column: main-start / main-end;
            margin-top: $s54;
        }

        span {
            margin-left: $s12;
            font-size: 1.25rem;

            @include media(tab-land) {
                font-size: 1rem;
            }
        }

        &:hover {
            text-decoration: underline;
            color: var(--green-7);
        }
    }

    .section-selector {
        grid-row: 2 / 5;
        grid-column: col-start 9 / main-end;

        @include media(phone) {
            grid-column: col-start 1/col-end 12;
        }
    }
}



.section-selector {
    margin-bottom: $s36;
    user-select: none;
    overflow: hidden;
    position: relative;
    
    &::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-left: 0.3125rem solid;
        border-right: 0;
        border-image: linear-gradient(to bottom,var(--whitish-2) 0.625rem,var(--light-green),var(--light-green),var(--light-green),var(--whitish-2))1 100%;
    }

    .section-item {
        background: none;
        border: none;
        outline: inherit;
        text-align: left;
        @extend .B-Rb20;
        position: relative;
        color: #555555;
        cursor: pointer;
        display: block;
        margin-left: -5px;
        width: 100%;
        height: $s54;
        padding: 0.78125em 2.1875em;
        overflow: hidden;
        z-index: 1;

        @include media(tab-land) {
            padding: 0.78125rem 0.9375rem;
        }

        @include media(phone) {
            background-image: unset;
            display: flex;
            justify-content: center;
            grid-column: main-start / main-end;
        }

        &::before {
            display: block;
            content:"";
            background-color: var(--light-green);
            position: absolute;
            left: -100%;
            top:0;
            transition: left var(--fast);
            height: 100%;
            width: 100%;
            border-radius: 0 1.6875rem 1.6875rem 0;
            z-index: -1;
        }

        &:hover {
            text-decoration: underline;
        }

        &.selected,
        &:hover {
            color: var(--green-4);
        }

        &.selected {
            padding-left: 2.8125rem;

            &:hover {
                text-decoration: none;
            }

            &::before {
                left: 0;
            }
        }
    }
}
