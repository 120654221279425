.rubrics-page {
    padding-top: 2.5rem;
    grid-column: 1 / -1;
    @include full-grid;
    animation: fadeIn var(--slow);

    .h4 {
        grid-row: 1 / 2;
        grid-column: main-start / main-end;
        color: var(--dark-grey-6);
        margin-bottom: $s18;

        @include media(tab-port) {
            margin-left: 1.25rem;
        }
    }

    .main-content {
        grid-column: main-start / col-end 8;
        @include media(tab-port) {
            grid-column: main-start / main-end;
        }

        @include media(phone) {
            grid-column: 1 / -1;
        }

        display: flex;
        flex-direction: column;
        & > * {
            margin-bottom: $s18;

            &:nth-last-child(2) {
                margin-bottom: $s54;
            }
        }
    }

    aside {
        grid-column: col-start 9 / main-end;
        & > * {
            margin-bottom: var(--gutter);
        }
        @include media(tab-port) {
            grid-row: 1;
            grid-column: main-start / main-end;
        }
    }
}
