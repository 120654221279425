.progkat {
    grid-column: main-start / main-end;
    display: grid;
    position: relative;
    grid-template-columns: [main-start] repeat(12, [col-start] minmax(0, var(--col-max-width)) [col-end]) [main-end];
    grid-template-rows: minmax(2.5rem, 1fr);
    border-radius: 0.375rem;
    box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.05);
    font-family: 'Roboto', sans-serif;
    grid-column-gap: var(--gutter);

    @include media(tab-port) {
        border-radius: 0;
    }

    & > *:not(.progkat__cover) {
        z-index: 1;
        margin-bottom: $s54;
    }

    &__cover {
        position: absolute;
        grid-row: 1  / 3;
        grid-column: 1 / -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 0;
        border-radius: 0.375rem;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: all var(--slow);
            backface-visibility: hidden;
        }
    }

    a {
        color: inherit;
    }

    &__title {
        @extend .h2;
        color: inherit;
        transition: var(--slow);
        cursor: pointer;

        &.hover {
            color: var(--green-3);
            text-decoration: underline;
        }
    }

    @include media(phone) {
        padding: 0;
        grid-column: 1 / -1;
        border-radius: 0;
    }

    .arrow {
        grid-row: 3/4;
        grid-column: -2 / -1;
        align-self: center;
        justify-self: center;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 3.125rem;
        height: 3.125rem;
        border: 0px;
        border-radius: 2.5rem;
        background-position: center;
        background-color: inherit;
        transition: background var(--fast), color var(--fast);

        font-size: 1.875rem;
        color: var(--light-grey-2);
        cursor: pointer;

        @include ripple(6.25rem, rgba(255,255,255,.6), .8s);

        &.hover {
            color: var(--green-4);
            background: var(--light-green) radial-gradient(circle, transparent 1%, var(--light-green) 1%) center/15000%;
        }

        @include media(tab-port) {
            display: none;
        }
    }

    .button {
        grid-column: 1 / -1;
        justify-self: start;
        margin-top: $s18;
        margin-left: 1.25rem;
        transition: all var(--fast);
        user-select: none;
        text-transform: uppercase;

        span {
            transition: all var(--fast);
        }

        &:hover {
            background-color: var(--green-3);
            color:white;
            border: transparent 2px solid;
    
            span {
                color: white;
            }
        }

        @include media(tab-port) {
            grid-column: 2 / -2;
            margin-left: 0;
        }

        @include media(tab-port) {
            margin-bottom: $s24;
        }
    
        @include media(phone) {
            justify-self: center;
        }
    }

    .review:hover ~ &__cover img {
        transform: scale(1.02);
    }

    .review {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        border-radius: 0.375rem;
        grid-row-start: 2;
        grid-column: 5 / 11;
        background: white;
        min-height: 9.375rem;
        padding: $s24;
        position: relative;

        *:not(.arrow) {
            grid-column: 1 / 6;

            @include media(tab-port) {
                grid-column: 1 / 7;
            }
        }

        @include media(tab-land) {
            grid-column: 3 / -2;
        }

        @include media(tab-port) {
            grid-column: 2 / -2;
            margin-left: 0;
            margin-bottom: $s24;
        }

        @include media(phone) {
            border-radius: 0;
            padding-bottom: 0.625rem;
            grid-column: 1 / -1;
        }

        .from {
            color: var(--edl-color-gray60);
            font-size: 1rem;
            margin-bottom: 0.625rem;
        }

        &__information {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            bottom: $s24;
            margin-top: $s24;

            @include media(phone) {
                & > * {
                    margin-bottom: $s12;
                }
            }

            .star {
                display: flex;
                justify-content: space-between;
                width: 12.5rem;
                margin-right: 2.5rem;
                align-items: center;

                &--empty {
                    color: var(--orange-2);
                    font-size: 1.4375rem;
                    position: relative;
                }

                &--half,
                &--full {
                    font-size: 1.4375rem;
                    color: var(--orange-1);
                }

                .star__border {
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: var(--orange-2);
                    z-index: 20;
                }

                &--half {
                    position: relative;
                }

                &--full {
                    position: relative;
                }

                &__rating {
                    font-weight: bold;
                    font-size: 1.25rem;
                }
            }

            .people {
                display: flex;
                align-items: center;

                & > *:not(:last-child) {
                    margin-right: 0.625rem;
                }

                p {
                    @extend .B-OS16;
                }

                span {
                    font-size: 1.5625rem;
                    color: inherit;
                }

                &__amount {
                    @extend .B-OSb20;
                    font-style: italic;
                }
            }
        }
    }
}
