.red {
    border: solid 1px red;
}

.u-margin-left-medium {
    margin-left: $s54 !important;

    @include media(tab-land) {
        margin-left: $s24 !important;
    }
}

.u-margin-right-medium {
    margin-right: $s36 !important;

    @include media(tab-land) {
        margin-right: .6rem !important;
    }
}

.u-margin-top-small {
    margin-top: $s12 !important;
}

.u-margin-top-small-2 {
    margin-top: $s12 !important;
}

.u-m-t-m-h2 {
    @include margin-top(Rb25, $s78);
}

.u-m-t-s-h2 {
    margin-top: 2.5rem !important;
}

.u-m-b-s-h2 {
    margin-bottom: 0.9375rem !important;
}

.u-m-b-s {
    margin-bottom: 2.8125rem !important;
}

.u-m-t-s {
    @include margin-top(OSb20, $s54);
}

.u-m-t-m {
    margin-top: 1.6875rem !important;
}

.u-m-t-l {
    margin-top: 4.5625rem !important;
}

.u-m-l-m {
    margin-left: 2.5rem !important;
}

.u-m-t-12 {
    margin-top: 0.75rem !important;
}

.u-m-l-a {
    width: calc(100% - 3.75rem) !important;
    margin-left: auto !important;
}

p.u-m-t-s {
    @include margin-top(OSb16, $s36);
}

.u-m-t-0 {
    margin-top: 0 !important;
}
