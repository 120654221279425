.RubricSelectorWrapper {
    grid-column: 1 / -1;
    z-index: 10;
    display: grid;
    margin: 0;
    box-sizing: inherit;
    grid-column: main-start / main-end;
    padding-top: 3rem;
}

.RubricSelectorWrapper__content {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
}

.RubricSelectorWrapper__content__left {
    display: grid;
    gap: 1rem;
}

.RubricSelectorWrapper__content__right {
    padding-left: 1rem;
}

.section-selector-wrapper__tovabb {
    cursor: pointer;
    color: var(--blue);
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    line-height: 1.875rem;
    grid-column: 10 / 14;
    grid-row: 4 / 5;
    align-self: end;
}

.section-selector-wrapper__tovabb:hover {
    text-decoration: underline;
}
