.author-box {
    grid-column: 1 / -1;
    background-color: var(--whitish);
    
    .inner {
        background-color: var(--light-grey-0);
        border: 1px solid var(--light-grey-2);
        @include border-radius;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 1.875rem;

        @include media(tab-port) {
            display: block;
        }

        &__image {
            cursor: pointer;
            border-radius: 50%;
            width: 8.75rem;
            height: 8.75rem;
            overflow: hidden;
            align-self: center;
            user-select: none;
            z-index: 100;

            @include media(tab-port) {
                margin: 0 auto;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: all var(--slow);
                backface-visibility: hidden;

                &:hover {
                    transform: scale(var(--medium-scale));
                }
            }
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: all var(--slow);

            &:hover {
                transform: scale(var(--medium-scale));
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: calc(100% - 11.25rem);

            @include media(tab-port) {
                max-width: 100%;
            }

            p:not(:last-child) {
                margin-bottom: $s12;
            }
        }

        .name {
            @extend .h2;
            margin-bottom: $s12;
            cursor: pointer;
            color: var(--edl-color-aqua60);
        }
    }
}
