.article {
  grid-column: 1 / -1;
  @include full-grid;
  grid-row-gap: 0;
  z-index: 910;
  position: relative;

  & ~ div {
    z-index: 100 !important;
  }

  .author-box {
    padding-bottom: 3.5rem !important;
    padding: var(--col-5-11-padding);
    grid-column: var(--col-5-11-grid);
  }

  .article__tags {
    grid-column: var(--col-5-11-grid);
    padding: var(--col-5-11-padding);
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: var(--whitish);
    @include media(tab-port) {
      padding: 1.25rem;
    }
  }

  .react-carousel {
    margin-top: 0;
    grid-column: main-start / main-end;

    @include media(tab-port) {
      --rc-border-radius: 0;
      grid-column: 1 / -1;
    }

    .thumbnail-wrapper {
      border: 1em solid var(--rc-slider-bg);
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  & > * {
    &:not(:last-child) {
      position: relative;
    }

    &:not(.article__social-media):not(.image-widget)::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include shadow;
      z-index: -1;
    }

    &:nth-last-child(2)::after,
    &:nth-last-child(2) {
      border-radius: 0 0 0.375rem 0.375rem;

      @include media(phone) {
        border-radius: 0;
      }
    }
  }

  &__cover {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    grid-row: 1 / 2;
    align-self: end;
    min-height: 21.875rem;
    height: 30vw;
    max-height: 37.5rem;
    width: 100%;
    overflow: hidden;
    @include shadow;

    img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    grid-column: col-start 4 / col-end 11;
    background-color: var(--whitish);
    padding: 1.25rem 1.875rem 0;
    grid-row: 1 / 2;
    align-self: end;
    border-radius: 0.375rem 0.375rem 0 0;
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;

    animation: fadeIn var(--slow);

    @include media(tab-land) {
      grid-column: col-start 2 / col-end 11;
    }

    @include media(tab-port) {
      grid-column: main-start / main-end;
      padding: 0.625rem 1.25rem 0 1.25rem;
    }

    @include media(phone) {
      border-radius: 0;
    }

    .info-lane {
      display: flex;
      justify-content: space-between;
      grid-template-columns: max-content max-content 1fr;
      grid-row-gap: 0.3125rem;
      align-items: center;

      @include media(tab-port) {
        grid-template-columns: 1fr;
        .author {
          justify-self: start;
          cursor: pointer;
        }
      }

      a {
        color: var(--edl-color-cerulean50);
      }
    }

    .label {
      @extend .label;
    }

    .eta {
      font-family: 'Open Sans Bold';
    }

    .author {
      cursor: pointer;
      color: var(--blue);
      font-style: italic;
      justify-self: end;
      grid-column: -1;

      &:hover {
        text-decoration: underline;
        color: var(--green-7);
      }

      @include media(tab-port) {
        grid-column: auto;
        justify-self: start;
      }
    }

    .title {
      @extend .h1;
      margin-top: $s12;
      color: var(--dark-grey-2) !important;
    }
  }

  @include media(phone) {
    & > * {
      grid-column: 1 / -1 !important;
    }
  }
}

.article__lead {
  grid-column: var(--col-4-11-grid);
  padding: var(--col-4-11-padding);
  background-color: var(--whitish);
  animation: fadeIn var(--slow);
}
