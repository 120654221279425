.text-widget {
    grid-column: var(--col-5-11-grid);
    padding: var(--col-5-11-padding);
    background-color: var(--whitish);
    position: relative;
    animation: fadeIn var(--slow);

    .iframe {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
        margin-top: $s36;
    }

    iframe:not(.ql-video) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    iframe.ql-video {
        display: block;
        margin: 0 auto;
        width: 500px;
        height: 282px;
    }

    h2 {
        margin-bottom: $s18;
        font-family: Roboto, sans-serif;
        line-height: 1.875rem;
        font-size: 1.5625rem;
    }

    .quote {
        color: var(--dark-grey-8);
        font-style: italic;
        @extend .B-Rb48;
        position: relative;
        margin-top: 5.125rem;
        margin-left: 6.25rem;
        z-index: 1;
        min-height: 7.5rem;

        @include media(phone) {
            margin-left: 0;
        }

        &::before {
            font-style: normal;
            font-family: FontAwesome;
            content: '\f10d';
            display: block;
            font-size: 15rem;
            color: var(--light-grey-2);
            position: absolute;
            top: 0;
            left: -6.25rem;
            z-index: -1;

            @include media(phone) {
                left: 0;
            }
        }
    }

    .highlight {
        text-align: center;
        color: var(--green-2);
        font-size: 1.9375rem;
        line-height: 2.25rem;
        font-style: italic;
        font-weight: bold;
    }

    .indent {
        margin-left: 2.5rem;
        position: relative;

        @include media(phone) {
            margin-left: 1.25rem;
        }

        &::after {
            position: absolute;
            left: -2.5rem;
            content: '';
            display: block;
            height: 100%;
            background-color: var(--light-green);
            width: 0.3125rem;
            top: 0;

            @include media(phone) {
                left: -1.25rem;
            }
        }
    }

    .text-separator {
        height: 0.375rem;
        width: 11.25rem;
        background-color: var(--light-green);
        margin: 0 auto;
        margin-top: 3.9375rem;
    }

    .normal {
        img {
            object-fit: cover;
            width: 100%;
        }
    }
}
