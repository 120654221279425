.tag {
    outline:none;
    border:none;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    background-color: var(--light-grey-1);
    display: inline-flex;
    align-items: center;
    padding: 0.4375rem 1.25rem;
    border-radius: 1.25rem;
    color: var(--dark-grey-4);
    margin-bottom: $s12;
    margin-right: calc(var(--gutter) / 2);
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: var(--green-3)!important;
        color: white;
    }
}
