.button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none !important;

    &--green {
        height: 2.8125rem;
        border: 2px solid var(--green-2);
        border-radius: 1.875rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;

        span {
            margin-right: 0.625rem;
            color: var(--green-2);
            font-size: 1.125rem;
        }
    }
    &--blue {
        background-color: var(--blue-2);
        color: white;
        text-transform: uppercase;
        font-family: Roboto, sans-serif;
        font-size: 1.25rem;
        font-weight: bold;
    }

    &--borrad-6 {
        border-radius: 0.375rem;
        padding: 0.5625rem 0.9375rem;
    }

    &--borrad-21 {
        border-radius: 1.3125rem;
        padding: 0.6875rem 1.875rem;
    }

    &:focus {
        box-shadow: var(--edl-btn-outline);
        outline: 2px solid var(--edl-color-white);
    }
}
